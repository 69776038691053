.member-catalog-filter {
    display: flex;
    flex-wrap: wrap;
    gap: $grid-gutter-width;

    > * {
        @include make-col;
    }

    .mod_article:first-child > .mod_app_member_catalog & {
        @include media-breakpoint-up(md) {
            @include margin-top(-50px);
        }
    }
}

.member-catalog-legend {
    @include icon($icon-hat, $margin: 0.5em);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid $border-color;

    &::before {
        color: $bg-dark;
    }
}

.member-catalog-item {
    @include padding-top(30px);
    @include padding-bottom(30px);
    position: relative;
    display: flex;
    flex-flow: column wrap;
    gap: calc($grid-gutter-width / 2) $grid-gutter-width;
    padding-left: 2em;
    line-height: calc(24 / 16);
    border-bottom: 1px solid $border-color;

    &__instructor {
        position: absolute;
        left: 0;
        color: $bg-dark;
    }

    &__name {
        font-weight: $font-weight-bold;
    }

    @include media-breakpoint-up(md) {
        flex-flow: row nowrap;

        > * {
            @include make-col(4);
        }
    }
}
