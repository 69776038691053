.content-text {
    ol,
    ul {
        padding-left: 2.4rem;

        li {
            margin-bottom: $vr;
        }
    }
}

%list-check {
    @include list-unstyled;
    padding-left: 0.4em;

    li {
        @include icon($icon-check-square);
        position: relative;
        margin-bottom: $vr;
        padding-left: 2em;

        &::before {
            position: absolute;
            top: 0.2em;
            left: 0;
            color: $primary;
            font-size: 20px;
        }
    }
}

.list-check,
.section-text--list-check {
    ul {
        @extend %list-check;
    }
}
