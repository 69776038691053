.content-table,
.content-text {
    table {
        @extend .table;
        line-height: calc(24 / 16);
    }
}

.table-th-last {
    tbody {
        th {
            font-weight: normal;

            ~ td:last-child {
                font-weight: bold;
                text-align: right;
            }
        }
    }
}
