.video_container {
    iframe {
        max-width: 100%;
        vertical-align: top;
        border: 0;
    }

    video {
        max-width: 100%;
        height: auto;
    }
}

.ratio,
.responsive {
    position: relative;
    width: 100%;

    &::before {
        display: block;
        padding-top: var(--#{$variable-prefix}aspect-ratio);
        content: '';
    }

    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@each $key, $ratio in $aspect-ratios {
    .ratio-#{$key} {
        --#{$variable-prefix}aspect-ratio: #{$ratio};
    }
}
