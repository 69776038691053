$sectionText: '.section-text';

// Text width
#{$sectionText}--limit-width {
    max-width: 35em;

    #{$sectionText}__content {
        width: 90%;
        max-width: 30em;
    }
}

#{$sectionText}--limit-width-lg {
    #{$sectionText}__content {
        width: 90%;
        max-width: 48.5em;
    }
}
