.banner-subpage {
    $this: &;

    &__title {
        line-height: 1;
        text-transform: uppercase;
    }

    &__headline {
        max-width: 13em;
        margin-bottom: 0.65em;
        line-height: 1.045;
    }

    &__text {
        @include font-size(20px);
        line-height: calc(30 / 20);
        letter-spacing: 0;
    }

    &--type-1 {
        padding-top: 125px;

        #{$this}__title {
            @include font-size(30px);
            margin-bottom: 0.85em;
            margin-left: var(--section-padding-inside-x);
        }

        #{$this}__headline {
            color: $primary;
        }

        #{$this}__text {
            max-width: 33em;
            margin-left: var(--section-padding-inside-x);

            > :last-child {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up($header-breakpoint) {
            @include padding-top(155px);
        }
    }

    &--type-2 {
        @include add-section-bg-100vw($primary);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: clamp(200px, 30vw, 590px);
        padding-top: 125px;
        padding-bottom: var(--banner-subpage-gap-bottom);
        color: $white;

        #{$this}__title {
            margin-bottom: 1.15em;
            font-size: 36px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        #{$this}__headline {
            color: inherit;
        }

        #{$this}__text {
            max-width: 40em;

            > :last-child {
                margin-bottom: 0;
            }
        }

        &::after {
            background-image: url('../../../images/bg-pattern.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        @include media-breakpoint-down($header-breakpoint) {
            #{$this}__content {
                margin-bottom: 90px;

                body:not(:has(.section-intro:first-child)) & {
                    margin-bottom: calc(125px - var(--banner-subpage-gap-bottom));
                }
            }

            &.has-section-intro {
                #{$this}__content {
                    margin-bottom: calc(125px - var(--banner-subpage-gap-bottom));
                }
            }
        }

        @include media-breakpoint-up($header-breakpoint) {
            @include padding-top(140px);

            #{$this}__title {
                @include font-size(48px);
            }
        }

        @include media-breakpoint-up(xxl) {
            padding-top: rem(156px);
        }
    }
}
