$box-image-text-carousel-breakpoint: sm;

.carousel-image-text {
    --image-text-offset: calc(var(--container-padding-x-half) * 0.5);

    @include media-breakpoint-down($box-image-text-carousel-breakpoint) {
        margin-right: calc(var(--image-text-offset) * -1);
    }

    @include media-breakpoint-up(lg) {
        .carousel-intro {
            padding-right: var(--column-one);
            padding-left: var(--column-one);
        }
    }
}

.box-image-text-carousel {
    $this: &;

    &__visual {
        margin-bottom: $vr-xl;

        &-inside {
            position: relative;

            img {
                width: 100%;
            }
        }

        &-text {
            @extend %text-hyphens;
            @include padding-top(27px);
            @include padding-bottom(27px);
            @include padding-right(30px);
            @include padding-left(30px);
            @include font-size(24px);
            position: absolute;
            right: 0;
            bottom: 12%;
            z-index: 1;
            width: 9.5em;
            color: $white;
            font-weight: $font-weight-bold;
            line-height: calc(30 / 24);
            text-transform: uppercase;
            background-color: $primary;
        }
    }

    &__headline {
        margin-bottom: 0.6em;
        line-height: calc(48 / 36);
    }

    &__text {
        @include font-size(14px);
        margin-bottom: $vr;
        line-height: calc(30 / 14);

        &:last-child {
            margin-bottom: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-down($box-image-text-carousel-breakpoint) {
        &__visual {
            &-inside {
                padding-right: var(--image-text-offset);
            }
        }

        &__content {
            padding-right: var(--image-text-offset);
        }
    }

    @include media-breakpoint-up($box-image-text-carousel-breakpoint) {
        @include make-row;
        height: 100%;

        > * {
            @include make-col-ready;
        }

        &__visual {
            @include make-col(5.5);
            margin-bottom: 0;

            &-inside {
                height: 100%;
            }

            img {
                height: 100%;
                object-fit: cover;
            }

            &-text {
                right: calc(var(--#{$prefix}gutter-x) * -0.5);
            }
        }

        &__content {
            @include make-col;
            @include padding-top(50px);
            @include padding-bottom(50px);
            padding-left: 30px;
        }

        &__headline {
            margin-bottom: 0.4em;
        }

        &__text {
            max-width: 25em;
        }
    }

    @include media-breakpoint-up(md) {
        &__visual {
            @include make-col(5);
        }

        &__content {
            &-inside {
                max-width: 27.5em;
                margin-left: auto;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__visual {
            &-text {
                right: rem(-50px);
            }
        }

        &__content {
            padding-right: var(--column-one);
        }
    }
}
