@charset "UTF-8";
/* Mixins */
/* Mixins */
/* Vertical align */
/* Sticky footer */
/* Link variant */
@keyframes link-underline-visible-animate {
  from {
    background-size: 0 var(--underline-size);
  }
  to {
    background-size: 100% var(--underline-size);
  }
}
/* Add icon */
/* Px to rem */
/* Dropdown navigation */
/* Add responsive properties */
/* Make row and columns */
/* Set gutter-y variable */
/* Add section bg */
/* Variables */
/* IcoMoon fonts */
/* Vertical rhythm */
/* Mobile menu */
/* Desktop dropdown navigation */
/* Vendor – Bootstrap */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #2572bd;
  --bs-secondary: #bda013;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 37, 114, 189;
  --bs-secondary-rgb: 189, 160, 19;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: rgb(14.8, 45.6, 75.6);
  --bs-secondary-text-emphasis: rgb(75.6, 64, 7.6);
  --bs-success-text-emphasis: rgb(10, 54, 33.6);
  --bs-info-text-emphasis: rgb(5.2, 80.8, 96);
  --bs-warning-text-emphasis: rgb(102, 77.2, 2.8);
  --bs-danger-text-emphasis: rgb(88, 21.2, 27.6);
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: rgb(211.4, 226.8, 241.8);
  --bs-secondary-bg-subtle: rgb(241.8, 236, 207.8);
  --bs-success-bg-subtle: rgb(209, 231, 220.8);
  --bs-info-bg-subtle: rgb(206.6, 244.4, 252);
  --bs-warning-bg-subtle: rgb(255, 242.6, 205.4);
  --bs-danger-bg-subtle: rgb(248, 214.6, 217.8);
  --bs-light-bg-subtle: rgb(251.5, 252, 252.5);
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: rgb(167.8, 198.6, 228.6);
  --bs-secondary-border-subtle: rgb(228.6, 217, 160.6);
  --bs-success-border-subtle: rgb(163, 207, 186.6);
  --bs-info-border-subtle: rgb(158.2, 233.8, 249);
  --bs-warning-border-subtle: rgb(255, 230.2, 155.8);
  --bs-danger-border-subtle: rgb(241, 174.2, 180.6);
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Open Sans", sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.875;
  --bs-body-color: #111315;
  --bs-body-color-rgb: 17, 19, 21;
  --bs-body-bg: rgb(235, 240, 245);
  --bs-body-bg-rgb: 235, 240, 245;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(17, 19, 21, 0.75);
  --bs-secondary-color-rgb: 17, 19, 21;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(17, 19, 21, 0.5);
  --bs-tertiary-color-rgb: 17, 19, 21;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #2572bd;
  --bs-link-color-rgb: 37, 114, 189;
  --bs-link-decoration: none;
  --bs-link-hover-color: rgb(29.6, 91.2, 151.2);
  --bs-link-hover-color-rgb: 30, 91, 151;
  --bs-code-color: #d63384;
  --bs-highlight-color: #111315;
  --bs-highlight-bg: rgb(255, 242.6, 205.4);
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #bbc0c2;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(37, 114, 189, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: rgb(42.5, 47.5, 52.5);
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: rgb(124.2, 170.4, 215.4);
  --bs-secondary-text-emphasis: rgb(215.4, 198, 113.4);
  --bs-success-text-emphasis: rgb(117, 183, 152.4);
  --bs-info-text-emphasis: rgb(109.8, 223.2, 246);
  --bs-warning-text-emphasis: rgb(255, 217.8, 106.2);
  --bs-danger-text-emphasis: rgb(234, 133.8, 143.4);
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: rgb(7.4, 22.8, 37.8);
  --bs-secondary-bg-subtle: rgb(37.8, 32, 3.8);
  --bs-success-bg-subtle: rgb(5, 27, 16.8);
  --bs-info-bg-subtle: rgb(2.6, 40.4, 48);
  --bs-warning-bg-subtle: rgb(51, 38.6, 1.4);
  --bs-danger-bg-subtle: rgb(44, 10.6, 13.8);
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: rgb(22.2, 68.4, 113.4);
  --bs-secondary-border-subtle: rgb(113.4, 96, 11.4);
  --bs-success-border-subtle: rgb(15, 81, 50.4);
  --bs-info-border-subtle: rgb(7.8, 121.2, 144);
  --bs-warning-border-subtle: rgb(153, 115.8, 4.2);
  --bs-danger-border-subtle: rgb(132, 31.8, 41.4);
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: rgb(124.2, 170.4, 215.4);
  --bs-link-hover-color: rgb(150.36, 187.32, 223.32);
  --bs-link-color-rgb: 124, 170, 215;
  --bs-link-hover-color-rgb: 150, 187, 223;
  --bs-code-color: rgb(230.4, 132.6, 181.2);
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: rgb(102, 77.2, 2.8);
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: rgb(117, 183, 152.4);
  --bs-form-valid-border-color: rgb(117, 183, 152.4);
  --bs-form-invalid-color: rgb(234, 133.8, 143.4);
  --bs-form-invalid-border-color: rgb(234, 133.8, 143.4);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.75em;
  font-weight: 800;
  line-height: 1.5;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.625rem + 4.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 5rem;
  }
}

h2, .h2 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.25rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.5;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 3rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 3rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 3.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 3.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 3rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 3rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 4rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 4rem;
  }
}
.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: var(--container-padding-x);
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .layout-container,
  .layout-header__inside,
  .header-container,
  .header-navigation__inside,
  .layout-footer__inside, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1208px;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(30, 91, 151, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(30, 91, 151, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(202, 179, 66, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(202, 179, 66, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-43 {
  --bs-aspect-ratio: 75%;
}

.ratio-3x2 {
  --bs-aspect-ratio: 66.6666666667%;
}

.ratio-32 {
  --bs-aspect-ratio: 66.6666666667%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-169 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-16x10 {
  --bs-aspect-ratio: 62.5%;
}

.ratio-1610 {
  --bs-aspect-ratio: 62.5%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.ratio-219 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.table, .content-table table,
.content-text table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > *, .content-table table > :not(caption) > * > *,
.content-text table > :not(caption) > * > * {
  padding: 0.45rem 0;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody, .content-table table > tbody,
.content-text table > tbody {
  vertical-align: inherit;
}
.table > thead, .content-table table > thead,
.content-text table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(211.4, 226.8, 241.8);
  --bs-table-border-color: rgb(169.12, 181.44, 193.44);
  --bs-table-striped-bg: rgb(200.83, 215.46, 229.71);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(190.26, 204.12, 217.62);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(195.545, 209.79, 223.665);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: rgb(241.8, 236, 207.8);
  --bs-table-border-color: rgb(193.44, 188.8, 166.24);
  --bs-table-striped-bg: rgb(229.71, 224.2, 197.41);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(217.62, 212.4, 187.02);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(223.665, 218.3, 192.215);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: rgb(209, 231, 220.8);
  --bs-table-border-color: rgb(167.2, 184.8, 176.64);
  --bs-table-striped-bg: rgb(198.55, 219.45, 209.76);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(188.1, 207.9, 198.72);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(193.325, 213.675, 204.24);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: rgb(206.6, 244.4, 252);
  --bs-table-border-color: rgb(165.28, 195.52, 201.6);
  --bs-table-striped-bg: rgb(196.27, 232.18, 239.4);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(185.94, 219.96, 226.8);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(191.105, 226.07, 233.1);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: rgb(255, 242.6, 205.4);
  --bs-table-border-color: rgb(204, 194.08, 164.32);
  --bs-table-striped-bg: rgb(242.25, 230.47, 195.13);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(229.5, 218.34, 184.86);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(235.875, 224.405, 189.995);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: rgb(248, 214.6, 217.8);
  --bs-table-border-color: rgb(198.4, 171.68, 174.24);
  --bs-table-striped-bg: rgb(235.6, 203.87, 206.91);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(223.2, 193.14, 196.02);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(229.4, 198.505, 201.465);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: rgb(198.4, 199.2, 200);
  --bs-table-striped-bg: rgb(235.6, 236.55, 237.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgb(223.2, 224.1, 225);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgb(229.4, 230.325, 231.25);
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: rgb(77.4, 80.6, 83.8);
  --bs-table-striped-bg: rgb(44.1, 47.9, 51.7);
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: rgb(55.2, 58.8, 62.4);
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: rgb(49.65, 53.35, 57.05);
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.btn, .mod_newscategories ul li:not(.reset) a, .formbody .submit {
  --bs-btn-padding-x: 1.175rem;
  --bs-btn-padding-y: 0.615rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.875;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 1.5rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .mod_newscategories ul li:not(.reset) a, .formbody .submit {
    transition: none;
  }
}
.btn:hover, .mod_newscategories ul li:not(.reset) a:hover, .formbody .submit:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover, .mod_newscategories ul li:not(.reset) .btn-check + a:hover, .formbody .btn-check + .submit:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible, .mod_newscategories ul li:not(.reset) a:focus-visible, .formbody .submit:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn, .mod_newscategories ul li:not(.reset) .btn-check:focus-visible + a, .formbody .btn-check:focus-visible + .submit {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .mod_newscategories ul li:not(.reset) .btn-check:checked + a, .formbody .btn-check:checked + .submit, :not(.btn-check) + .btn:active, .mod_newscategories ul li:not(.reset) :not(.btn-check) + a:active, .formbody :not(.btn-check) + .submit:active, .btn:first-child:active, .mod_newscategories ul li:not(.reset) a:first-child:active, .formbody .submit:first-child:active, .btn.active, .mod_newscategories ul li:not(.reset) a.active, .formbody .active.submit, .btn.show, .mod_newscategories ul li:not(.reset) a.show, .formbody .show.submit {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, .mod_newscategories ul li:not(.reset) .btn-check:checked + a:focus-visible, .formbody .btn-check:checked + .submit:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .mod_newscategories ul li:not(.reset) :not(.btn-check) + a:active:focus-visible, .formbody :not(.btn-check) + .submit:active:focus-visible, .btn:first-child:active:focus-visible, .mod_newscategories ul li:not(.reset) a:first-child:active:focus-visible, .formbody .submit:first-child:active:focus-visible, .btn.active:focus-visible, .mod_newscategories ul li:not(.reset) a.active:focus-visible, .formbody .active.submit:focus-visible, .btn.show:focus-visible, .mod_newscategories ul li:not(.reset) a.show:focus-visible, .formbody .show.submit:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn, .mod_newscategories ul li:not(.reset) .btn-check:checked:focus-visible + a, .formbody .btn-check:checked:focus-visible + .submit {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .mod_newscategories ul li:not(.reset) a:disabled, .formbody .submit:disabled, .btn.disabled, .mod_newscategories ul li:not(.reset) a.disabled, .formbody .disabled.submit, fieldset:disabled .btn, fieldset:disabled .mod_newscategories ul li:not(.reset) a, .mod_newscategories ul li:not(.reset) fieldset:disabled a, fieldset:disabled .formbody .submit, .formbody fieldset:disabled .submit {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary, .formbody .submit {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2572bd;
  --bs-btn-border-color: #2572bd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(31.45, 96.9, 160.65);
  --bs-btn-hover-border-color: rgb(29.6, 91.2, 151.2);
  --bs-btn-focus-shadow-rgb: 70, 135, 199;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(29.6, 91.2, 151.2);
  --bs-btn-active-border-color: rgb(27.75, 85.5, 141.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2572bd;
  --bs-btn-disabled-border-color: #2572bd;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #bda013;
  --bs-btn-border-color: #bda013;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(198.9, 174.25, 54.4);
  --bs-btn-hover-border-color: rgb(195.6, 169.5, 42.6);
  --bs-btn-focus-shadow-rgb: 161, 136, 16;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(202.2, 179, 66.2);
  --bs-btn-active-border-color: rgb(195.6, 169.5, 42.6);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #bda013;
  --bs-btn-disabled-border-color: #bda013;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(21.25, 114.75, 71.4);
  --bs-btn-hover-border-color: rgb(20, 108, 67.2);
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(20, 108, 67.2);
  --bs-btn-active-border-color: rgb(18.75, 101.25, 63);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(49.3, 209.95, 242.25);
  --bs-btn-hover-border-color: rgb(37.2, 207.3, 241.5);
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(61.4, 212.6, 243);
  --bs-btn-active-border-color: rgb(37.2, 207.3, 241.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(255, 202.3, 44.2);
  --bs-btn-hover-border-color: rgb(255, 199.2, 31.8);
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(255, 205.4, 56.6);
  --bs-btn-active-border-color: rgb(255, 199.2, 31.8);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(187, 45.05, 58.65);
  --bs-btn-hover-border-color: rgb(176, 42.4, 55.2);
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(176, 42.4, 55.2);
  --bs-btn-active-border-color: rgb(165, 39.75, 51.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgb(210.8, 211.65, 212.5);
  --bs-btn-hover-border-color: rgb(198.4, 199.2, 200);
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgb(198.4, 199.2, 200);
  --bs-btn-active-border-color: rgb(186, 186.75, 187.5);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(66.3, 69.7, 73.1);
  --bs-btn-hover-border-color: rgb(55.2, 58.8, 62.4);
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(77.4, 80.6, 83.8);
  --bs-btn-active-border-color: rgb(55.2, 58.8, 62.4);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary, .mod_newscategories ul li:not(.reset) a {
  --bs-btn-color: #2572bd;
  --bs-btn-border-color: #2572bd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2572bd;
  --bs-btn-hover-border-color: #2572bd;
  --bs-btn-focus-shadow-rgb: 37, 114, 189;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2572bd;
  --bs-btn-active-border-color: #2572bd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2572bd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2572bd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #bda013;
  --bs-btn-border-color: #bda013;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #bda013;
  --bs-btn-hover-border-color: #bda013;
  --bs-btn-focus-shadow-rgb: 189, 160, 19;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #bda013;
  --bs-btn-active-border-color: #bda013;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #bda013;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #bda013;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 70, 135, 199;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .formbody .submit {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-font-size: 1.15rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.875;
}

.col-form-label-lg {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 1.15rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control, select,
textarea,
input.text {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.875;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #000;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control, select,
  textarea,
  input.text {
    transition: none;
  }
}
.form-control[type=file], select[type=file],
textarea[type=file],
input[type=file].text {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]), select[type=file]:not(:disabled):not([readonly]),
textarea[type=file]:not(:disabled):not([readonly]),
input[type=file].text:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus, select:focus,
textarea:focus,
input.text:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: rgb(146, 184.5, 222);
  outline: 0;
  box-shadow: 0;
}
.form-control::-webkit-date-and-time-value, select::-webkit-date-and-time-value,
textarea::-webkit-date-and-time-value,
input.text::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.875em;
  margin: 0;
}
.form-control::-webkit-datetime-edit, select::-webkit-datetime-edit,
textarea::-webkit-datetime-edit,
input.text::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder, select::placeholder,
textarea::placeholder,
input.text::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled, select:disabled,
textarea:disabled,
input.text:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button, select::file-selector-button,
textarea::file-selector-button,
input.text::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button, select::file-selector-button,
  textarea::file-selector-button,
  input.text::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button, select:hover:not(:disabled):not([readonly])::file-selector-button,
textarea:hover:not(:disabled):not([readonly])::file-selector-button,
input.text:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.875;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.875em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.875em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 1.5rem;
  font-size: 1.15rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.25rem 1.5rem;
  margin: -0.25rem -1.5rem;
  margin-inline-end: 1.5rem;
}

textarea.form-control,
textarea {
  min-height: calc(1.875em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.875em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.875em + 0.5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.875em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 0;
}
.form-control-color.form-control-sm {
  height: calc(1.875em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.875em + 0.5rem + calc(var(--bs-border-width) * 2));
}

.form-select, select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.875;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #000;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select, select {
    transition: none;
  }
}
.form-select:focus, select:focus {
  border-color: rgb(146, 184.5, 222);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(37, 114, 189, 0.25);
}
.form-select[multiple], select[multiple], .form-select[size]:not([size="1"]), select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled, select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring, select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.5rem;
  font-size: 1.15rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select, [data-bs-theme=dark] select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check, .radio_container > span,
.checkbox_container > span {
  display: block;
  min-height: 1.875rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input, .radio_container > span .form-check-input, .radio_container > span input,
.checkbox_container > span .form-check-input,
.checkbox_container > span input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input, .form-check-reverse .radio_container > span input, .radio_container > span .form-check-reverse input,
.form-check-reverse .checkbox_container > span input,
.checkbox_container > span .form-check-reverse input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input, .radio_container > span input,
.checkbox_container > span input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.4375em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #000;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox], .radio_container > span input[type=checkbox],
.checkbox_container > span input[type=checkbox] {
  border-radius: 0;
}
.form-check-input[type=radio], .radio_container > span input[type=radio],
.checkbox_container > span input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active, .radio_container > span input:active,
.checkbox_container > span input:active {
  filter: brightness(90%);
}
.form-check-input:focus, .radio_container > span input:focus,
.checkbox_container > span input:focus {
  border-color: rgb(146, 184.5, 222);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(37, 114, 189, 0.25);
}
.form-check-input:checked, .radio_container > span input:checked,
.checkbox_container > span input:checked {
  background-color: #2572bd;
  border-color: #2572bd;
}
.form-check-input:checked[type=checkbox], .radio_container > span input:checked[type=checkbox],
.checkbox_container > span input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio], .radio_container > span input:checked[type=radio],
.checkbox_container > span input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate, .radio_container > span input[type=checkbox]:indeterminate,
.checkbox_container > span input[type=checkbox]:indeterminate {
  background-color: #2572bd;
  border-color: #2572bd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled, .radio_container > span input:disabled,
.checkbox_container > span input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .radio_container > span .form-check-input[disabled] ~ label,
.checkbox_container > span .form-check-input[disabled] ~ label, .radio_container > span input[disabled] ~ .form-check-label, .radio_container > span input[disabled] ~ label,
.checkbox_container > span input[disabled] ~ .form-check-label,
.checkbox_container > span input[disabled] ~ label, .form-check-input:disabled ~ .form-check-label, .radio_container > span .form-check-input:disabled ~ label,
.checkbox_container > span .form-check-input:disabled ~ label, .radio_container > span input:disabled ~ .form-check-label, .radio_container > span input:disabled ~ label,
.checkbox_container > span input:disabled ~ .form-check-label,
.checkbox_container > span input:disabled ~ label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input, .form-switch .radio_container > span input, .radio_container > span .form-switch input,
.form-switch .checkbox_container > span input,
.checkbox_container > span .form-switch input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input, .form-switch .radio_container > span input, .radio_container > span .form-switch input,
  .form-switch .checkbox_container > span input,
  .checkbox_container > span .form-switch input {
    transition: none;
  }
}
.form-switch .form-check-input:focus, .form-switch .radio_container > span input:focus, .radio_container > span .form-switch input:focus,
.form-switch .checkbox_container > span input:focus,
.checkbox_container > span .form-switch input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb%28146, 184.5, 222%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked, .form-switch .radio_container > span input:checked, .radio_container > span .form-switch input:checked,
.form-switch .checkbox_container > span input:checked,
.checkbox_container > span .form-switch input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input, .form-switch.form-check-reverse .radio_container > span input, .radio_container > span .form-switch.form-check-reverse input,
.form-switch.form-check-reverse .checkbox_container > span input,
.checkbox_container > span .form-switch.form-check-reverse input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .mod_newscategories ul li:not(.reset) .btn-check[disabled] + a, .formbody .btn-check[disabled] + .submit, .btn-check:disabled + .btn, .mod_newscategories ul li:not(.reset) .btn-check:disabled + a, .formbody .btn-check:disabled + .submit {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus), [data-bs-theme=dark] .form-switch .radio_container > span input:not(:checked):not(:focus), .radio_container > span [data-bs-theme=dark] .form-switch input:not(:checked):not(:focus),
[data-bs-theme=dark] .form-switch .checkbox_container > span input:not(:checked):not(:focus),
.checkbox_container > span [data-bs-theme=dark] .form-switch input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px rgb(235, 240, 245), 0;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px rgb(235, 240, 245), 0;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #2572bd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: rgb(189.6, 212.7, 235.2);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #2572bd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: rgb(189.6, 212.7, 235.2);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control, .form-floating > select,
.form-floating > textarea,
.form-floating > input.text,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: 2.2rem;
  min-height: 2.2rem;
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 0.4rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control, .form-floating > select,
.form-floating > textarea,
.form-floating > input.text,
.form-floating > .form-control-plaintext {
  padding: 0.4rem 0.75rem;
}
.form-floating > .form-control::placeholder, .form-floating > select::placeholder,
.form-floating > textarea::placeholder,
.form-floating > input.text::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > select:focus,
.form-floating > textarea:focus,
.form-floating > input.text:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > select:not(:placeholder-shown),
.form-floating > textarea:not(:placeholder-shown),
.form-floating > input.text:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.2rem;
  padding-bottom: 0.4rem;
}
.form-floating > .form-control:-webkit-autofill, .form-floating > select:-webkit-autofill,
.form-floating > textarea:-webkit-autofill,
.form-floating > input.text:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.2rem;
  padding-bottom: 0.4rem;
}
.form-floating > .form-select, .form-floating > select {
  padding-top: 1.2rem;
  padding-bottom: 0.4rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > textarea:focus ~ label,
.form-floating > input.text:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > textarea:not(:placeholder-shown) ~ label,
.form-floating > input.text:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label,
.form-floating > select ~ label {
  color: rgba(var(--bs-body-color-rgb), 1);
  transform: scale(0.7) translateY(-1.75rem) translateX(0);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > textarea:focus ~ label::after,
.form-floating > input.text:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > textarea:not(:placeholder-shown) ~ label::after,
.form-floating > input.text:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after,
.form-floating > select ~ label::after {
  position: absolute;
  inset: 0.4rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: 0;
}
.form-floating > .form-control:-webkit-autofill ~ label, .form-floating > select:-webkit-autofill ~ label,
.form-floating > textarea:-webkit-autofill ~ label,
.form-floating > input.text:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 1);
  transform: scale(0.7) translateY(-1.75rem) translateX(0);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .input-group > select,
.input-group > textarea,
.input-group > input.text,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus, .input-group > select:focus,
.input-group > textarea:focus,
.input-group > input.text:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn, .input-group .mod_newscategories ul li:not(.reset) a, .mod_newscategories ul li:not(.reset) .input-group a, .input-group .formbody .submit, .formbody .input-group .submit {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus, .input-group .mod_newscategories ul li:not(.reset) a:focus, .mod_newscategories ul li:not(.reset) .input-group a:focus, .input-group .formbody .submit:focus, .formbody .input-group .submit:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.875;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid #000;
  border-radius: 0;
}

.input-group-lg > .form-control, .input-group-lg > select,
.input-group-lg > textarea,
.input-group-lg > input.text,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.mod_newscategories ul li:not(.reset) .input-group-lg > a,
.formbody .input-group-lg > .submit {
  padding: 0.25rem 1.5rem;
  font-size: 1.15rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control, .input-group-sm > select,
.input-group-sm > textarea,
.input-group-sm > input.text,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.mod_newscategories ul li:not(.reset) .input-group-sm > a,
.formbody .input-group-sm > .submit {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select, .input-group-lg > select,
.input-group-sm > .form-select,
.input-group-sm > select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > select,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > textarea,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > input.text,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > select,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > textarea,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > input.text,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > select,
.input-group > .form-floating:not(:first-child) > textarea,
.input-group > .form-floating:not(:first-child) > input.text,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated select:valid,
.was-validated textarea:valid,
.was-validated input.text:valid, .form-control.is-valid, select.is-valid,
textarea.is-valid,
input.is-valid.text {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.875em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.46875em + 0.1875rem) center;
  background-size: calc(0.9375em + 0.375rem) calc(0.9375em + 0.375rem);
}
.was-validated .form-control:valid:focus, .was-validated select:valid:focus,
.was-validated textarea:valid:focus,
.was-validated input.text:valid:focus, .form-control.is-valid:focus, select.is-valid:focus,
textarea.is-valid:focus,
input.is-valid.text:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated textarea:valid, textarea.form-control.is-valid,
textarea.is-valid {
  padding-right: calc(1.875em + 0.75rem);
  background-position: top calc(0.46875em + 0.1875rem) right calc(0.46875em + 0.1875rem);
}

.was-validated .form-select:valid, .was-validated select:valid, .form-select.is-valid, select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .was-validated select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.9375em + 0.375rem) calc(0.9375em + 0.375rem);
}
.was-validated .form-select:valid:focus, .was-validated select:valid:focus, .form-select.is-valid:focus, select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.875em + 0.75rem));
}

.was-validated .form-check-input:valid, .was-validated .radio_container > span input:valid, .radio_container > span .was-validated input:valid,
.was-validated .checkbox_container > span input:valid,
.checkbox_container > span .was-validated input:valid, .form-check-input.is-valid, .radio_container > span input.is-valid,
.checkbox_container > span input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .was-validated .radio_container > span input:valid:checked, .radio_container > span .was-validated input:valid:checked,
.was-validated .checkbox_container > span input:valid:checked,
.checkbox_container > span .was-validated input:valid:checked, .form-check-input.is-valid:checked, .radio_container > span input.is-valid:checked,
.checkbox_container > span input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .was-validated .radio_container > span input:valid:focus, .radio_container > span .was-validated input:valid:focus,
.was-validated .checkbox_container > span input:valid:focus,
.checkbox_container > span .was-validated input:valid:focus, .form-check-input.is-valid:focus, .radio_container > span input.is-valid:focus,
.checkbox_container > span input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .was-validated .radio_container > span .form-check-input:valid ~ label, .radio_container > span .was-validated .form-check-input:valid ~ label,
.was-validated .checkbox_container > span .form-check-input:valid ~ label,
.checkbox_container > span .was-validated .form-check-input:valid ~ label, .was-validated .radio_container > span input:valid ~ .form-check-label, .was-validated .radio_container > span input:valid ~ label, .radio_container > span .was-validated input:valid ~ .form-check-label, .radio_container > span .was-validated input:valid ~ label,
.was-validated .checkbox_container > span input:valid ~ .form-check-label,
.was-validated .checkbox_container > span input:valid ~ label,
.checkbox_container > span .was-validated input:valid ~ .form-check-label,
.checkbox_container > span .was-validated input:valid ~ label, .form-check-input.is-valid ~ .form-check-label, .radio_container > span .form-check-input.is-valid ~ label,
.checkbox_container > span .form-check-input.is-valid ~ label, .radio_container > span input.is-valid ~ .form-check-label, .radio_container > span input.is-valid ~ label,
.checkbox_container > span input.is-valid ~ .form-check-label,
.checkbox_container > span input.is-valid ~ label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback, .form-check-inline .radio_container > span input ~ .valid-feedback, .radio_container > span .form-check-inline input ~ .valid-feedback,
.form-check-inline .checkbox_container > span input ~ .valid-feedback,
.checkbox_container > span .form-check-inline input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .was-validated .input-group > select:not(:focus):valid,
.was-validated .input-group > textarea:not(:focus):valid,
.was-validated .input-group > input.text:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .input-group > select:not(:focus).is-valid,
.input-group > textarea:not(:focus).is-valid,
.input-group > input.text:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
select.error ~ .invalid-feedback,
textarea.error ~ .invalid-feedback,
input.text.error ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
select.error ~ .invalid-tooltip,
textarea.error ~ .invalid-tooltip,
input.text.error ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated select:invalid,
.was-validated textarea:invalid,
.was-validated input.text:invalid, .form-control.is-invalid, select.is-invalid, select.error,
textarea.is-invalid,
textarea.error,
input.is-invalid.text,
input.text.error {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.875em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.46875em + 0.1875rem) center;
  background-size: calc(0.9375em + 0.375rem) calc(0.9375em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .was-validated select:invalid:focus,
.was-validated textarea:invalid:focus,
.was-validated input.text:invalid:focus, .form-control.is-invalid:focus, select.is-invalid:focus, select.error:focus,
textarea.is-invalid:focus,
textarea.error:focus,
input.is-invalid.text:focus,
input.text.error:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated textarea:invalid, textarea.form-control.is-invalid,
textarea.is-invalid,
textarea.error {
  padding-right: calc(1.875em + 0.75rem);
  background-position: top calc(0.46875em + 0.1875rem) right calc(0.46875em + 0.1875rem);
}

.was-validated .form-select:invalid, .was-validated select:invalid, .form-select.is-invalid, select.is-invalid, select.error,
textarea.form-select.error,
input.form-select.text.error {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .was-validated select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), select.is-invalid:not([multiple]):not([size]), select.error:not([multiple]):not([size]),
textarea.form-select.error:not([multiple]):not([size]),
input.form-select.text.error:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], select.is-invalid:not([multiple])[size="1"], select.error:not([multiple])[size="1"],
textarea.form-select.error:not([multiple])[size="1"],
input.form-select.text.error:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.9375em + 0.375rem) calc(0.9375em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .was-validated select:invalid:focus, .form-select.is-invalid:focus, select.is-invalid:focus, select.error:focus,
textarea.form-select.error:focus,
input.form-select.text.error:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid, select.form-control-color.error,
textarea.form-control-color.error,
input.form-control-color.text.error {
  width: calc(3rem + calc(1.875em + 0.75rem));
}

.was-validated .form-check-input:invalid, .was-validated .radio_container > span input:invalid, .radio_container > span .was-validated input:invalid,
.was-validated .checkbox_container > span input:invalid,
.checkbox_container > span .was-validated input:invalid, .form-check-input.is-invalid, .radio_container > span input.is-invalid,
.checkbox_container > span input.is-invalid, select.form-check-input.error,
textarea.form-check-input.error,
input.form-check-input.text.error,
.radio_container > span input.text.error,
.checkbox_container > span input.text.error {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .was-validated .radio_container > span input:invalid:checked, .radio_container > span .was-validated input:invalid:checked,
.was-validated .checkbox_container > span input:invalid:checked,
.checkbox_container > span .was-validated input:invalid:checked, .form-check-input.is-invalid:checked, .radio_container > span input.is-invalid:checked,
.checkbox_container > span input.is-invalid:checked, select.form-check-input.error:checked,
textarea.form-check-input.error:checked,
input.form-check-input.text.error:checked,
.radio_container > span input.text.error:checked,
.checkbox_container > span input.text.error:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .was-validated .radio_container > span input:invalid:focus, .radio_container > span .was-validated input:invalid:focus,
.was-validated .checkbox_container > span input:invalid:focus,
.checkbox_container > span .was-validated input:invalid:focus, .form-check-input.is-invalid:focus, .radio_container > span input.is-invalid:focus,
.checkbox_container > span input.is-invalid:focus, select.form-check-input.error:focus,
textarea.form-check-input.error:focus,
input.form-check-input.text.error:focus,
.radio_container > span input.text.error:focus,
.checkbox_container > span input.text.error:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .was-validated .radio_container > span .form-check-input:invalid ~ label, .radio_container > span .was-validated .form-check-input:invalid ~ label,
.was-validated .checkbox_container > span .form-check-input:invalid ~ label,
.checkbox_container > span .was-validated .form-check-input:invalid ~ label, .was-validated .radio_container > span input:invalid ~ .form-check-label, .was-validated .radio_container > span input:invalid ~ label, .radio_container > span .was-validated input:invalid ~ .form-check-label, .radio_container > span .was-validated input:invalid ~ label,
.was-validated .checkbox_container > span input:invalid ~ .form-check-label,
.was-validated .checkbox_container > span input:invalid ~ label,
.checkbox_container > span .was-validated input:invalid ~ .form-check-label,
.checkbox_container > span .was-validated input:invalid ~ label, .form-check-input.is-invalid ~ .form-check-label, .radio_container > span .form-check-input.is-invalid ~ label,
.checkbox_container > span .form-check-input.is-invalid ~ label, .radio_container > span input.is-invalid ~ .form-check-label, .radio_container > span input.is-invalid ~ label,
.checkbox_container > span input.is-invalid ~ .form-check-label,
.checkbox_container > span input.is-invalid ~ label, select.form-check-input.error ~ .form-check-label, .radio_container > span select.form-check-input.error ~ label,
.checkbox_container > span select.form-check-input.error ~ label,
textarea.form-check-input.error ~ .form-check-label,
.radio_container > span textarea.form-check-input.error ~ label,
.checkbox_container > span textarea.form-check-input.error ~ label,
input.form-check-input.text.error ~ .form-check-label,
.radio_container > span input.text.error ~ .form-check-label,
.radio_container > span input.text.error ~ label,
.checkbox_container > span input.text.error ~ .form-check-label,
.checkbox_container > span input.text.error ~ label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback, .form-check-inline .radio_container > span input ~ .invalid-feedback, .radio_container > span .form-check-inline input ~ .invalid-feedback,
.form-check-inline .checkbox_container > span input ~ .invalid-feedback,
.checkbox_container > span .form-check-inline input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .was-validated .input-group > select:not(:focus):invalid,
.was-validated .input-group > textarea:not(:focus):invalid,
.was-validated .input-group > input.text:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .input-group > select:not(:focus).is-invalid, .input-group > select.error:not(:focus),
.input-group > textarea:not(:focus).is-invalid,
.input-group > textarea.error:not(:focus),
.input-group > input.text:not(:focus).is-invalid,
.input-group > input.text.error:not(:focus),
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid,
.input-group > select.form-floating.error:not(:focus-within),
.input-group > textarea.form-floating.error:not(:focus-within),
.input-group > input.form-floating.text.error:not(:focus-within) {
  z-index: 4;
}

.alert, .ce_form .form-confirmation, p.empty {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0;
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success, .ce_form .form-confirmation {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning, p.empty {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 3.5rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 3.5rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 3.5rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 3.5rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 3.5rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 3rem !important;
  }
  .mt-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 4rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2rem !important;
  }
  .me-sm-6 {
    margin-right: 2.5rem !important;
  }
  .me-sm-7 {
    margin-right: 3rem !important;
  }
  .me-sm-8 {
    margin-right: 3.5rem !important;
  }
  .me-sm-9 {
    margin-right: 4rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2rem !important;
  }
  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 3rem !important;
  }
  .ms-sm-8 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 4rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 3rem !important;
  }
  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 4rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2rem !important;
  }
  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 3rem !important;
  }
  .pe-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-9 {
    padding-right: 4rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2rem !important;
  }
  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 3rem !important;
  }
  .ps-sm-8 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2rem !important;
  }
  .mt-md-6 {
    margin-top: 2.5rem !important;
  }
  .mt-md-7 {
    margin-top: 3rem !important;
  }
  .mt-md-8 {
    margin-top: 3.5rem !important;
  }
  .mt-md-9 {
    margin-top: 4rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2rem !important;
  }
  .me-md-6 {
    margin-right: 2.5rem !important;
  }
  .me-md-7 {
    margin-right: 3rem !important;
  }
  .me-md-8 {
    margin-right: 3.5rem !important;
  }
  .me-md-9 {
    margin-right: 4rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 3rem !important;
  }
  .mb-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 4rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2rem !important;
  }
  .ms-md-6 {
    margin-left: 2.5rem !important;
  }
  .ms-md-7 {
    margin-left: 3rem !important;
  }
  .ms-md-8 {
    margin-left: 3.5rem !important;
  }
  .ms-md-9 {
    margin-left: 4rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2rem !important;
  }
  .pt-md-6 {
    padding-top: 2.5rem !important;
  }
  .pt-md-7 {
    padding-top: 3rem !important;
  }
  .pt-md-8 {
    padding-top: 3.5rem !important;
  }
  .pt-md-9 {
    padding-top: 4rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2rem !important;
  }
  .pe-md-6 {
    padding-right: 2.5rem !important;
  }
  .pe-md-7 {
    padding-right: 3rem !important;
  }
  .pe-md-8 {
    padding-right: 3.5rem !important;
  }
  .pe-md-9 {
    padding-right: 4rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 3rem !important;
  }
  .pb-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 4rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2rem !important;
  }
  .ps-md-6 {
    padding-left: 2.5rem !important;
  }
  .ps-md-7 {
    padding-left: 3rem !important;
  }
  .ps-md-8 {
    padding-left: 3.5rem !important;
  }
  .ps-md-9 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2rem !important;
  }
  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 3rem !important;
  }
  .mt-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 4rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2rem !important;
  }
  .me-lg-6 {
    margin-right: 2.5rem !important;
  }
  .me-lg-7 {
    margin-right: 3rem !important;
  }
  .me-lg-8 {
    margin-right: 3.5rem !important;
  }
  .me-lg-9 {
    margin-right: 4rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2rem !important;
  }
  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 3rem !important;
  }
  .ms-lg-8 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 4rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2rem !important;
  }
  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 3rem !important;
  }
  .pt-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 4rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2rem !important;
  }
  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 3rem !important;
  }
  .pe-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-9 {
    padding-right: 4rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2rem !important;
  }
  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 3rem !important;
  }
  .ps-lg-8 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2rem !important;
  }
  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 3rem !important;
  }
  .mt-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 4rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2rem !important;
  }
  .me-xl-6 {
    margin-right: 2.5rem !important;
  }
  .me-xl-7 {
    margin-right: 3rem !important;
  }
  .me-xl-8 {
    margin-right: 3.5rem !important;
  }
  .me-xl-9 {
    margin-right: 4rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2rem !important;
  }
  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 3rem !important;
  }
  .ms-xl-8 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 4rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2rem !important;
  }
  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 3rem !important;
  }
  .pt-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 4rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2rem !important;
  }
  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 3rem !important;
  }
  .pe-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-9 {
    padding-right: 4rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2rem !important;
  }
  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 3rem !important;
  }
  .ps-xl-8 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2rem !important;
  }
  .m-xxl-6 {
    margin: 2.5rem !important;
  }
  .m-xxl-7 {
    margin: 3rem !important;
  }
  .m-xxl-8 {
    margin: 3.5rem !important;
  }
  .m-xxl-9 {
    margin: 4rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2rem !important;
  }
  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 3rem !important;
  }
  .mt-xxl-8 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 4rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 2rem !important;
  }
  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 3rem !important;
  }
  .me-xxl-8 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-9 {
    margin-right: 4rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 2rem !important;
  }
  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 3rem !important;
  }
  .ms-xxl-8 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 4rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2rem !important;
  }
  .p-xxl-6 {
    padding: 2.5rem !important;
  }
  .p-xxl-7 {
    padding: 3rem !important;
  }
  .p-xxl-8 {
    padding: 3.5rem !important;
  }
  .p-xxl-9 {
    padding: 4rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2rem !important;
  }
  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 3rem !important;
  }
  .pt-xxl-8 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 4rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 2rem !important;
  }
  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 3rem !important;
  }
  .pe-xxl-8 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 4rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 2rem !important;
  }
  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 3rem !important;
  }
  .ps-xxl-8 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 4rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* Vendor – IcoMoon fonts */
@font-face {
  font-weight: normal;
  font-family: "icomoon";
  font-style: normal;
  src: url("../icons/fonts/icomoon.ttf") format("truetype"), url("../icons/fonts/icomoon.woff") format("woff");
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  font-weight: normal;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: never;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close::before {
  content: "\e903";
}

.icon-chevrons-right::before {
  content: "\e90e";
}

.icon-chevrons-left::before {
  content: "\e90f";
}

.icon-chevron-right::before {
  content: "\e910";
}

.icon-chevron-left::before {
  content: "\e911";
}

.icon-chevron-down::before {
  content: "\e912";
}

.icon-chevron-up::before {
  content: "\e913";
}

.icon-check-square::before {
  content: "\e909";
}

.icon-zoom::before {
  content: "\e90a";
}

.icon-hat::before {
  content: "\e908";
}

.icon-arrow-right::before {
  content: "\e906";
}

.icon-arrow-left::before {
  content: "\e90b";
}

.icon-search::before {
  content: "\e900";
}

.icon-download::before {
  content: "\e901";
}

/* Vendor – fontsource */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-ext-300-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-300-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("~@fontsource/open-sans/files/open-sans-greek-ext-300-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("~@fontsource/open-sans/files/open-sans-greek-300-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("~@fontsource/open-sans/files/open-sans-hebrew-300-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("~@fontsource/open-sans/files/open-sans-vietnamese-300-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("~@fontsource/open-sans/files/open-sans-latin-ext-300-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("~@fontsource/open-sans/files/open-sans-latin-300-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-300-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-ext-400-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-400-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-greek-ext-400-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-greek-400-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-hebrew-400-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-vietnamese-400-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-latin-ext-400-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-latin-400-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-ext-400-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-400-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-greek-ext-400-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-greek-400-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-hebrew-400-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-vietnamese-400-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-latin-ext-400-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("~@fontsource/open-sans/files/open-sans-latin-400-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-400-italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-ext-600-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-600-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-greek-ext-600-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-greek-600-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-hebrew-600-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-vietnamese-600-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-latin-ext-600-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-latin-600-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-ext-600-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-600-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-greek-ext-600-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-greek-600-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-hebrew-600-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-vietnamese-600-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-latin-ext-600-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("~@fontsource/open-sans/files/open-sans-latin-600-italic.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-600-italic.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-ext-700-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-700-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("~@fontsource/open-sans/files/open-sans-greek-ext-700-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("~@fontsource/open-sans/files/open-sans-greek-700-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("~@fontsource/open-sans/files/open-sans-hebrew-700-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("~@fontsource/open-sans/files/open-sans-vietnamese-700-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("~@fontsource/open-sans/files/open-sans-latin-ext-700-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("~@fontsource/open-sans/files/open-sans-latin-700-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-700-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-ext-800-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("~@fontsource/open-sans/files/open-sans-cyrillic-800-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("~@fontsource/open-sans/files/open-sans-greek-ext-800-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("~@fontsource/open-sans/files/open-sans-greek-800-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("~@fontsource/open-sans/files/open-sans-hebrew-800-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("~@fontsource/open-sans/files/open-sans-vietnamese-800-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("~@fontsource/open-sans/files/open-sans-latin-ext-800-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("~@fontsource/open-sans/files/open-sans-latin-800-normal.woff2") format("woff2"), url("~@fontsource/open-sans/files/open-sans-all-800-normal.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Base */
/* Helper class */
/* Reset style */
.header-navigation__mobile-menu ul, .pagination ul, .header-links ul, .reset {
  margin: 0;
  padding: 0;
  list-style: none;
  background: transparent;
  border: 0;
}

/* Links */
a {
  transition: color 0.4s;
}
@media (prefers-reduced-motion: reduce) {
  a {
    transition: none;
  }
}
.content-text a {
  --underline-size: 1px;
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  background-position: 0 1.2em;
  background-size: 100% 1px;
  transition: background-size 0.8s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
}
.content-text a:hover {
  animation: link-underline-visible-animate 0.4s;
}

.link-more, .content-hyperlink a {
  color: #2572bd;
  text-decoration: none;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  color: #2572bd;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.link-more:focus, .content-hyperlink a:focus, .link-more:visited, .content-hyperlink a:visited {
  color: #2572bd;
}
.link-more:hover, .content-hyperlink a:hover {
  color: rgb(24.4756637168, 75.4115044248, 125.0243362832);
}
.link-more:hover, .content-hyperlink a:hover, .link-more:focus, .content-hyperlink a:focus, .link-more:active, .content-hyperlink a:active {
  text-decoration: none;
}
.link-more::after, .content-hyperlink a::after {
  font-weight: normal;
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\e906";
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 0.8em;
}
.link-more::after, .content-hyperlink a::after {
  transition: transform 0.4s;
}
@media (prefers-reduced-motion: reduce) {
  .link-more::after, .content-hyperlink a::after {
    transition: none;
  }
}
.link-more:hover::after, .content-hyperlink a:hover::after {
  transform: translateX(0.25em);
}

.back {
  margin-top: 1.21875rem;
  padding-top: 1em;
  border-top: 1px solid #bbc0c2;
}
.back a {
  transition: background-size 0.4s, color 0.4s;
  color: #111315;
  text-decoration: none;
  border-radius: 0.375rem;
  background-image: linear-gradient(to right, #2572bd 0%, #2572bd 100%);
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.4em 0.8em;
  font-size: 1rem;
  background-color: #f8f9fa;
  background-repeat: no-repeat;
  background-size: 0 100%;
}
@media (prefers-reduced-motion: reduce) {
  .back a {
    transition: none;
  }
}
.back a::before {
  font-weight: normal;
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\e90b";
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.5em;
}
.back a:focus, .back a:visited {
  color: #111315;
}
.back a:hover {
  color: #fff;
}
.back a:hover, .back a:focus, .back a:active {
  text-decoration: none;
}
.back a:hover {
  background-size: 100% 100%;
}

.content-text + .content-hyperlink {
  margin-top: calc(var(--content-element-margin) * -0.25);
}

/* Text */
.listing-card--type-2__text a, .box-image-text-carousel__visual-text, .call-to-action__content, .banner__content-text, h1,
.h1, .footer-headline {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.mb-article {
  margin-bottom: var(--article-margin) !important;
}

.mb-content-element {
  margin-bottom: var(--content-element-margin) !important;
}

.mb-content-element-lg {
  margin-bottom: var(--content-element-margin-lg) !important;
}

.mb-container-reset, .section--bg-white-full-width:last-child, .section--bg-white:last-child {
  margin-bottom: calc(var(--container-margin-bottom) * -1) !important;
}

p.empty {
  text-align: center;
}

.header-navigation__content {
  overflow: auto;
}
.header-navigation__content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgb(24.4756637168, 75.4115044248, 125.0243362832);
}
.header-navigation__content::-webkit-scrollbar-track {
  background-color: rgb(24.4756637168, 75.4115044248, 125.0243362832);
  box-shadow: inset 0 0 0 1px rgb(24.4756637168, 75.4115044248, 125.0243362832);
}
.header-navigation__content::-webkit-scrollbar-thumb {
  background: #000;
  border: solid rgb(24.4756637168, 75.4115044248, 125.0243362832);
  border-width: 0 1px;
}

/* Reset */
select,
input[type=checkbox],
input[type=checkbox] + label,
input[type=radio],
input[type=radio] + label {
  cursor: pointer;
}

select[disabled],
input[disabled],
input[type=checkbox][disabled] + label,
input[type=radio][disabled] + label {
  cursor: not-allowed;
}

table {
  width: 100%;
}
table td,
table th {
  padding: 6px;
}

ul ul {
  margin-bottom: 0;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

/* Base */
/* Vertical rhythm */
ul,
ol,
dd,
p,
figure,
pre,
table,
fieldset {
  margin-top: 0;
  margin-bottom: 1.21875rem;
}

hr {
  margin-top: 1.21875rem;
  margin-bottom: 1.21875rem;
}

img[sizes] {
  width: auto;
}

*[class^=ce_],
*[class^=mod_],
*[class^=content-] {
  margin-top: 0;
  margin-bottom: var(--content-element-margin);
}

.mod_article {
  margin-bottom: var(--article-margin);
}
.mod_article:last-child {
  margin-bottom: 0;
}

.layout-header *[class^=mod_],
.layout-footer *[class^=mod_] {
  margin-bottom: 0;
}

/* Grid */
.mod_article .row .ce_column {
  margin-bottom: 0;
}
.mod_article .row .ce_column > *[class^=ce_],
.mod_article .row .ce_column > *[class^=mod_],
.mod_article .row .ce_column > *[class^=content-] {
  padding-right: 0;
  padding-left: 0;
}
.mod_article .row.ce_row {
  margin-bottom: 0;
}

/* Float classes */
.float_left {
  float: left;
}
@media (max-width: 575.98px) {
  .float_left {
    float: none;
  }
}

figure.float_left {
  margin-right: 1.21875rem;
  margin-bottom: 1.21875rem;
}
@media (max-width: 575.98px) {
  figure.float_left {
    margin-right: 0;
  }
}

.float_right {
  float: right;
}
@media (max-width: 575.98px) {
  .float_right {
    float: none;
  }
}

figure.float_right {
  margin-bottom: 1.21875rem;
  margin-left: 1.21875rem;
}
@media (max-width: 575.98px) {
  figure.float_right {
    margin-left: 0;
  }
}

.content-text::after {
  display: block;
  clear: both;
  content: "";
}

/* Hide invisible elements */
.invisible {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
}

/* Typography */
html {
  font-size: 15px;
}
@media (min-width: 768px) {
  html {
    font-size: 15px;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 16px;
  }
}

/* Form */
.formbody .widget {
  margin-bottom: 2.2rem;
}
.formbody .widget:last-child {
  margin-bottom: 0;
}
.formbody .widget > label,
.formbody .widget > fieldset > legend {
  display: block;
  margin-bottom: 0.2rem;
  font-weight: bold;
}
.formbody .widget > label span.mandatory,
.formbody .widget > fieldset > legend span.mandatory {
  margin-left: 2px;
  color: #dc3545;
}
.formbody .widget > label {
  font-size: 0.875rem;
  margin-bottom: 0.875rem;
}
.formbody p.error {
  margin-bottom: 0.2rem;
  color: #dc3545;
  font-weight: bold;
}
.formbody .radio_container,
.formbody .checkbox_container {
  margin: 0;
  padding: 0;
  border: 0;
}
.formbody .radio_container > span,
.formbody .checkbox_container > span {
  display: block;
}
.formbody .captcha {
  width: 75px;
  margin-right: 8px;
  text-align: center;
}
.formbody .captcha_text {
  font-weight: bold;
}
.formbody .widget-submit {
  margin-top: var(--content-element-margin);
  text-align: center;
}
/* Ajax form */
.ce_form form {
  transition: opacity 0.4s;
}
.ce_form form[data-ajax-form=loading] {
  cursor: progress;
  opacity: 0.5;
}
.ce_form form[data-ajax-form=loading] .formbody {
  pointer-events: none;
}
.ce_form .form-confirmation *:last-child {
  margin-bottom: 0;
}

/* Floating label */
/* autoprefixer: off */
.form-floating select,
.form-floating input.text {
  padding-left: 0;
  border-width: 0 0 var(--bs-border-width);
}
.form-floating label {
  color: #8a8e8d;
  font-style: italic;
}
.form-floating textarea {
  height: auto;
  padding-top: 1.2rem;
  padding-bottom: 0.4rem;
}
.form-floating > .form-control:focus, .form-floating > select:focus,
.form-floating > textarea:focus,
.form-floating > input.text:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > select:not(:placeholder-shown),
.form-floating > textarea:not(:placeholder-shown),
.form-floating > input.text:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.2rem;
  padding-bottom: 0.4rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > textarea:focus ~ label,
.form-floating > input.text:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > textarea:not(:placeholder-shown) ~ label,
.form-floating > input.text:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label,
.form-floating > select ~ label {
  color: #2572bd;
  font-weight: 700;
  font-style: unset;
  transform: scale(0.7) translateY(-1.75rem) translateX(0);
  opacity: 0;
}
.form-floating textarea:focus ~ label, .form-floating textarea:not(:placeholder-shown) ~ label {
  transform: scale(0.7) translateY(-0.2rem) translateX(0.3em);
}
.form-floating > .form-control:focus ~ label, .form-floating > select:focus ~ label,
.form-floating > textarea:focus ~ label,
.form-floating > input.text:focus ~ label {
  opacity: 1;
}
.form-floating select,
.form-floating input.text {
  padding-left: 0;
}
.form-floating select ~ label,
.form-floating input.text ~ label {
  padding-left: 0;
}
.form-floating select.select {
  padding-top: 0.4rem;
}
.form-floating select.select:focus {
  box-shadow: none;
}

/* autoprefixer: on */
.radio_container > span,
.checkbox_container > span {
  clear: both;
}
.radio_container > span label a,
.checkbox_container > span label a {
  color: inherit;
  text-decoration: none;
  --underline-size: 1px;
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  background-position: 0 1.2em;
  background-size: 100% 1px;
  transition: background-size 0.8s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
}
.radio_container > span label a:focus, .radio_container > span label a:visited,
.checkbox_container > span label a:focus,
.checkbox_container > span label a:visited {
  color: inherit;
}
.radio_container > span label a:hover,
.checkbox_container > span label a:hover {
  color: #2572bd;
}
.radio_container > span label a:hover, .radio_container > span label a:focus, .radio_container > span label a:active,
.checkbox_container > span label a:hover,
.checkbox_container > span label a:focus,
.checkbox_container > span label a:active {
  text-decoration: none;
}
.radio_container > span label a:hover,
.checkbox_container > span label a:hover {
  animation: link-underline-visible-animate 0.4s;
}
.radio_container > span input[required] + label::after,
.checkbox_container > span input[required] + label::after {
  position: absolute;
  content: "*";
}

.widget-explanation {
  color: #8a8e8d;
  font-size: 12px;
}
.widget + .widget-explanation {
  margin-top: -1.76rem;
}

.widget + .widget-textarea {
  margin-top: 3.08rem;
}

/* Layout */
/* Header */
.header-container {
  font-size: 0.75rem;
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
}
body.menu-active .header-container {
  color: #fff;
}

.header-content {
  display: flex;
  align-items: center;
}
@media (max-width: 1399.98px) {
  .header-content {
    margin-right: var(--header-container-complete-right);
  }
}
@media (max-width: 767.98px) {
  .header-content {
    margin-right: calc(var(--container-padding-x) * -0.5);
  }
}

.header-logo {
  transition: padding-top 0.4s;
  align-self: center;
  max-width: 60%;
  margin-right: 2rem;
}
@media (prefers-reduced-motion: reduce) {
  .header-logo {
    transition: none;
  }
}
.header-logo__menu-open {
  display: none;
}
body.menu-active .header-logo__menu-open {
  display: block;
}
body.menu-active .header-logo__menu-closed {
  display: none;
}
@media (min-width: 768px) {
  .header-logo {
    padding-top: 1rem;
  }
  .sticky .header-logo {
    padding-top: 0;
  }
}

.header-links {
  display: none;
}
@media (min-width: 768px) {
  .header-links {
    margin-right: calc(1.29375rem + 0.525vw);
    display: flex;
    align-items: center;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .header-links {
    margin-right: 1.6875rem;
  }
}
@media (min-width: 768px) {
  .header-links ul {
    display: flex;
    gap: 3.2em;
  }
  .header-links a {
    color: inherit;
    text-decoration: none;
    text-decoration: none;
    background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
    transition: background-size 1.2s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
  }
  .header-links a:focus, .header-links a:visited {
    color: inherit;
  }
  .header-links a:hover {
    opacity: 0.8;
  }
  .header-links a:hover, .header-links a:focus, .header-links a:active {
    text-decoration: none;
  }
  .header-links a:hover, .header-links a.active {
    text-decoration: none;
    background-position: 0 1.2em;
    background-size: 100% 1px;
  }
}

.header-search__button {
  display: none;
}
.header-search__form {
  margin-bottom: var(--content-element-margin);
}
.header-search__form-close {
  display: none;
}
.header-search__form .formbody {
  position: relative;
}
.header-search__form .formbody .widget {
  margin: 0;
}
.header-search__form .formbody input.text {
  padding-right: 3em;
  padding-left: 1.5em;
  color: inherit;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 2em;
}
.header-search__form .formbody input.text::placeholder {
  color: #fff;
  font-style: italic;
}
.header-search__form .formbody .widget-submit {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.header-search__form .formbody .widget-submit button {
  min-width: unset;
  height: 100%;
  padding: 0 1em;
  background: transparent;
  border: 0;
}
@media (min-width: 768px) {
  .header-search {
    margin-right: calc(1.275rem + 0.3vw);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .header-search {
    margin-right: 1.5rem;
  }
}
@media (min-width: 768px) {
  .header-search__button {
    transition: background-color 0.4s, color 0.4s;
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6em;
    height: 1.6em;
    font-size: 1.375rem;
    background-color: transparent;
    border-width: 0;
    border-radius: 50%;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .header-search__button {
    transition: none;
  }
}
@media (min-width: 768px) {
  .header-search__button:focus, .header-search__button:visited {
    color: inherit;
  }
  .header-search__button:hover {
    color: #fff;
  }
  .header-search__button:hover, .header-search__button:focus, .header-search__button:active {
    text-decoration: none;
  }
  .header-search__button:hover {
    background-color: #2572bd;
  }
  .header-search__form {
    position: relative;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    display: none;
    align-items: center;
    padding: 1.5rem;
    color: #fff;
    background: #2572bd;
  }
  .header-search__form::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    z-index: -2;
    width: calc(100vw - var(--scrollbar-width));
    background-color: #2572bd;
    transform: translateX(-50%);
    content: "";
  }
  .header-search__form-close {
    display: block;
    margin-left: 1rem;
    font-size: 2em;
    cursor: pointer;
  }
  .header-search__form-close:hover {
    color: rgb(235, 240, 245);
  }
  .header-search__form form {
    flex-grow: 1;
  }
  .header-search.active .header-search__form {
    display: flex;
  }
}

.header-placeholder + .sticky-header {
  transition: background-color 0.4s;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background-color: rgb(235, 240, 245);
}
@media (prefers-reduced-motion: reduce) {
  .header-placeholder + .sticky-header {
    transition: none;
  }
}
body.menu-active .header-placeholder + .sticky-header {
  background-color: transparent !important;
  box-shadow: unset !important;
}
.header-placeholder + .sticky-header.sticky {
  background-color: #fff;
  box-shadow: 0 0 8px rgba(33, 37, 41, 0.25);
}

.sticky-header--init * {
  transition: none !important;
}

/* Footer */
html {
  position: relative;
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.layout-wrapper > *:not(.layout-container) {
  flex-shrink: 0;
}

.layout-container {
  flex: 1 1 auto;
}

.layout-footer {
  padding-bottom: calc(1.3125rem + 0.75vw);
  position: relative;
  position: relative;
  padding-top: 75px;
  color: #fff;
  line-height: 24px;
}
@media (min-width: 1200px) {
  .layout-footer {
    padding-bottom: 1.875rem;
  }
}
.layout-footer::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: -2;
  width: calc(100vw - var(--scrollbar-width));
  background-color: #2572bd;
  transform: translateX(-50%);
  content: "";
}
.layout-footer a {
  color: #fff;
  text-decoration: none;
}
.layout-footer a:focus, .layout-footer a:visited {
  color: #fff;
}
.layout-footer a:hover {
  color: #fff;
}
.layout-footer a:hover, .layout-footer a:focus, .layout-footer a:active {
  text-decoration: none;
}
.layout-footer::after {
  background-image: url("../../images/bg-pattern.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (min-width: 768px) {
  .layout-footer {
    padding-top: calc(1.4875rem + 2.85vw);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .layout-footer {
    padding-top: 3.625rem;
  }
}

.footer-content {
  margin-bottom: calc(1.375rem + 1.5vw);
  padding-bottom: calc(1.375rem + 1.5vw);
  display: grid;
  grid-template: 1fr 1fr/auto 1fr;
  grid-template-areas: "col-1 col-1" "col-2 col-2" "col-3 col-3";
  gap: calc(30px / 2);
  border-bottom: 1px solid #bbc0c2;
}
@media (min-width: 1200px) {
  .footer-content {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .footer-content {
    padding-bottom: 2.5rem;
  }
}
.footer-content .footer-headline {
  grid-area: col-1;
}
.footer-content .footer-contact {
  grid-area: col-2;
}
.footer-content .footer-newsletter {
  grid-area: col-3;
}
@media (min-width: 768px) {
  .footer-content {
    grid-template-areas: "col-1 col-1 col-2" "col-3 col-3 col-2";
  }
}

.footer-headline {
  max-width: 18em;
  margin-bottom: 1.5em;
  font-size: 2rem;
  hyphenate-limit-chars: 10 4 3;
}
@media (max-width: 767.98px) {
  .footer-headline {
    line-height: 1.3333333333;
  }
}
@media (min-width: 768px) {
  .footer-headline {
    font-size: calc(1.375rem + 1.5vw);
    margin-bottom: 0.9em;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .footer-headline {
    font-size: 2.5rem;
  }
}

.footer-logo {
  margin-bottom: 1.21875rem;
}

.footer-address {
  margin-bottom: 1.21875rem;
}

.footer-newsletter__headline {
  font-size: 0.875rem;
  margin-bottom: 1.2em;
}

.footer-contact {
  font-size: 0.875rem;
}
.footer-contact__links {
  margin-bottom: 1.21875rem;
}
.footer-contact__phone a {
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  transition: background-size 1.2s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
}
.footer-contact__phone a:hover, .footer-contact__phone a.active {
  text-decoration: none;
  background-position: 0 1.2em;
  background-size: 100% 1px;
}
.footer-contact__email a {
  --underline-size: 1px;
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  background-position: 0 1.2em;
  background-size: 100% 1px;
  transition: background-size 0.8s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
}
.footer-contact__email a:hover {
  animation: link-underline-visible-animate 0.4s;
}
@media (min-width: 768px) {
  .footer-contact {
    padding-top: 1.0625rem;
  }
}
@media (min-width: 992px) {
  .footer-contact {
    padding-right: calc(1.625rem + 4.5vw);
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .footer-contact {
    padding-right: 5rem;
  }
}

.footer-navigation {
  margin-bottom: calc(1.625rem + 4.5vw);
}
@media (min-width: 1200px) {
  .footer-navigation {
    margin-bottom: 5rem;
  }
}
.footer-navigation ul {
  padding-left: 0;
  list-style: none;
  font-weight: bold;
}
.footer-navigation ul a {
  color: inherit;
  text-decoration: none;
}
.footer-navigation ul a:focus, .footer-navigation ul a:visited {
  color: inherit;
}
.footer-navigation ul a:hover {
  color: #bda013;
}
.footer-navigation ul a:hover, .footer-navigation ul a:focus, .footer-navigation ul a:active {
  text-decoration: none;
}
.footer-navigation ul strong {
  color: #bda013;
  font-weight: inherit;
}
@media (max-width: 767.98px) {
  .footer-navigation ul li {
    margin-bottom: 0.8em;
  }
}
@media (min-width: 768px) {
  .footer-navigation ul {
    --bs-gutter-x: 2.8125rem;
    display: flex;
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
  }
  .footer-navigation ul > * {
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
  }
}

.footer-terms {
  font-size: 0.75rem;
  text-align: center;
}
@media (min-width: 768px) {
  .footer-terms {
    --bs-gutter-x: 40px;
    display: flex;
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
    justify-content: center;
  }
  .footer-terms > * {
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
  }
}

.footer-terms__menu ul {
  padding-left: 0;
  list-style: none;
  --bs-gutter-x: 40px;
  display: flex;
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0;
}
.footer-terms__menu ul > * {
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
}
.footer-terms__menu ul li {
  position: relative;
}
.footer-terms__menu ul li a {
  --underline-size: 1px;
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  background-position: 0 1.2em;
  background-size: 100% 1px;
  transition: background-size 0.8s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
}
.footer-terms__menu ul li a:hover {
  animation: link-underline-visible-animate 0.4s;
}
.footer-terms__menu ul li strong {
  color: #8a8e8d;
}
.footer-terms__menu ul li::after {
  position: absolute;
  top: 0.5em;
  right: 0;
  bottom: 0.5em;
  width: 1px;
  background-color: #fff;
  content: "";
}
@media (max-width: 767.98px) {
  .footer-terms__menu ul li:last-child::after {
    content: none;
  }
}

/* Content */
.btn-arrow-right::after {
  font-weight: normal;
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\e906";
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-arrow-right::after {
  margin-left: 1em;
  font-size: 0.9em;
}

.btn-submit, .formbody .submit {
  min-width: 10em;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0;
}

.mod_newscategories ul, .job-list__filters, .swiper-pagination-buttons {
  --gap: clamp(0.65rem, 2vw, 1rem);
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--gap) * 1.3) var(--gap);
  justify-content: center;
}

/* Miscellaneous */
:root {
  --scrollbar-width: 0px;
  --header-height: 0px;
  --container-padding-x-half: calc(var(--container-padding-x) / 2);
  --content-element-margin-lg: calc(var(--content-element-margin) * 1.8);
  --content-element-margin-xl: 90px;
  --container-padding-x-sm: 10px;
  --section-padding-inside-x-fill: 0px;
  --section-padding-inside-x-filled: calc(var(--section-padding-inside-x) + var(--section-padding-inside-x-fill));
  --content-width: min(calc(100vw - var(--container-padding-x)), 1208px);
  --column-one: calc(var(--content-width) / 12);
  --container-width: min(
      calc(1208px - var(--container-padding-x) + var(--scrollbar-width)),
      calc(100vw - var(--container-padding-x) + var(--scrollbar-width))
  );
  --container-complete: calc(((100vw - var(--container-width)) * 0.5) * -1);
  --header-container-complete-right: 0px;
  --container-padding-x: 40px;
  --container-margin-top: 90px;
  --container-margin-bottom: 3rem;
  --article-margin: 90px;
  --article-margin-lg: 110px;
  --article-padding-inside-y: 3.5rem;
  --section-padding-inside-x: var(--column-one);
  --content-element-margin: 2.5rem;
  --banner-subpage-gap-bottom: 4.5rem;
}
@media (min-width: 768px) {
  :root {
    --container-margin-top: 6rem;
  }
}
@media (min-width: 1200px) {
  :root {
    --container-margin-top: 9.4rem;
  }
}
@media (min-width: 768px) {
  :root {
    --container-margin-bottom: 5rem;
  }
}
@media (min-width: 1200px) {
  :root {
    --container-margin-bottom: 6.5rem;
  }
}
@media (min-width: 768px) {
  :root {
    --article-margin: 5rem;
  }
}
@media (min-width: 992px) {
  :root {
    --article-margin: 7.75rem;
  }
}
@media (min-width: 768px) {
  :root {
    --article-padding-inside-y: 5rem;
  }
}
@media (min-width: 992px) {
  :root {
    --article-padding-inside-y: 7.75rem;
  }
}
@media (min-width: 768px) {
  :root {
    --content-element-margin: 3rem;
  }
}
@media (min-width: 768px) {
  :root {
    --banner-subpage-gap-bottom: 7rem;
  }
}
@media (min-width: 992px) {
  :root {
    --banner-subpage-gap-bottom: 8rem;
  }
}
@media (min-width: 1400px) {
  :root {
    --banner-subpage-gap-bottom: 11.25rem;
  }
}
@media (min-width: 992px) {
  :root {
    --section-padding-inside-x-fill: calc(var(--column-one) * 2);
  }
}
@media (min-width: 768px) and (max-width: 1399.98px) {
  :root {
    --header-container-complete-right: calc(var(--container-padding-x-sm) + var(--container-padding-x) * -0.5);
  }
}

html,
body {
  overflow-x: hidden;
}

.container,
.layout-container,
.layout-header__inside,
.header-container,
.header-navigation__inside,
.layout-footer__inside {
  --bs-gutter-x: var(--container-padding-x);
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.layout-container {
  margin-top: var(--container-margin-top);
  margin-bottom: var(--container-margin-bottom);
}

body {
  letter-spacing: 0.02em;
}

.video_container iframe {
  max-width: 100%;
  vertical-align: top;
  border: 0;
}
.video_container video {
  max-width: 100%;
  height: auto;
}

.ratio,
.responsive {
  position: relative;
  width: 100%;
}
.ratio::before,
.responsive::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > *,
.responsive > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-43 {
  --bs-aspect-ratio: 75%;
}

.ratio-3x2 {
  --bs-aspect-ratio: 66.6666666667%;
}

.ratio-32 {
  --bs-aspect-ratio: 66.6666666667%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-169 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-16x10 {
  --bs-aspect-ratio: 62.5%;
}

.ratio-1610 {
  --bs-aspect-ratio: 62.5%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.ratio-219 {
  --bs-aspect-ratio: 42.8571428571%;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  letter-spacing: 0;
}

h1,
.h1 {
  hyphenate-limit-chars: 10 4 3;
}
@media (max-width: 767.98px) {
  h1,
  .h1 {
    font-size: 50px;
  }
}

@media (max-width: 767.98px) {
  h2,
  .h2 {
    font-size: 27px;
  }
  .section--bg-white h2,
  .section--bg-white .h2 {
    font-size: 36px;
  }
}

@media (max-width: 767.98px) {
  .h2-sm,
  .content-gallery > h2,
  .content-gallery > .h2 {
    font-size: 21px;
  }
}

@media (max-width: 767.98px) {
  .section--bg-white h3, h3.job-item__category,
  .section--bg-white .h3,
  .h3.job-item__category {
    font-size: 24px;
  }
}

.headline-group {
  display: flex;
  gap: 1.25rem;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.02em;
}
.headline-group::after {
  flex-grow: 1;
  height: 1px;
  background-color: #000;
  content: "";
}

.headline-section__main {
  margin-bottom: 0;
  font-size: 27px;
}
.headline-section__subheadline {
  font-size: calc(1.3125rem + 0.75vw);
  color: #8a8e8d;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0;
}
@media (min-width: 1200px) {
  .headline-section__subheadline {
    font-size: 1.875rem;
  }
}
@media (min-width: 768px) {
  .headline-section {
    font-size: calc(1.35rem + 1.2vw);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .headline-section {
    font-size: 2.25rem;
  }
}

.headline-intro {
  margin-bottom: var(--content-element-margin);
}
@media (min-width: 768px) {
  .headline-intro {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headline-intro__main {
    margin-bottom: 0.2em;
  }
  .headline-intro__action {
    margin-left: 30px;
  }
}
@media (min-width: 992px) {
  .headline-intro {
    margin-right: var(--section-padding-inside-x);
    margin-left: var(--section-padding-inside-x);
  }
}

.headline-light-primary {
  margin-bottom: 1.5em;
  color: #2572bd;
  font-weight: 400;
  letter-spacing: 0.02em;
}
.accordion .headline-light-primary:first-child {
  margin-top: -0.9rem;
}

.content-gallery figure a[data-lightbox],
.content-image figure a[data-lightbox],
.content-text figure a[data-lightbox] {
  position: relative;
  display: inline-block;
  max-width: 100%;
  text-decoration: none;
  vertical-align: top;
}
.content-gallery figure a[data-lightbox]::after,
.content-image figure a[data-lightbox]::after,
.content-text figure a[data-lightbox]::after {
  font-weight: normal;
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\e90a";
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.content-gallery figure a[data-lightbox] img,
.content-image figure a[data-lightbox] img,
.content-text figure a[data-lightbox] img {
  display: block;
}
.content-gallery figure a[data-lightbox]::before,
.content-image figure a[data-lightbox]::before,
.content-text figure a[data-lightbox]::before {
  transition: opacity 0.4s;
  position: absolute;
  inset: 0;
  z-index: 1;
  background-color: #2572bd;
  opacity: 0;
  content: "";
}
@media (prefers-reduced-motion: reduce) {
  .content-gallery figure a[data-lightbox]::before,
  .content-image figure a[data-lightbox]::before,
  .content-text figure a[data-lightbox]::before {
    transition: none;
  }
}
.content-gallery figure a[data-lightbox]::after,
.content-image figure a[data-lightbox]::after,
.content-text figure a[data-lightbox]::after {
  transition: transform 0.4s, opacity 0.4s;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  color: #fff;
  font-size: 3.1em;
  transform: translate(-50%, 50%);
  opacity: 0;
}
@media (prefers-reduced-motion: reduce) {
  .content-gallery figure a[data-lightbox]::after,
  .content-image figure a[data-lightbox]::after,
  .content-text figure a[data-lightbox]::after {
    transition: none;
  }
}
.content-gallery figure a[data-lightbox]:hover::before,
.content-image figure a[data-lightbox]:hover::before,
.content-text figure a[data-lightbox]:hover::before {
  opacity: 0.8;
}
.content-gallery figure a[data-lightbox]:hover::after,
.content-image figure a[data-lightbox]:hover::after,
.content-text figure a[data-lightbox]:hover::after {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.content-gallery figure::after,
.content-image figure::after,
.content-text figure::after {
  display: block;
  clear: both;
  content: "";
}
.caption {
  margin-top: 1em;
  font-size: 0.85em;
  font-style: italic;
  line-height: 1.2;
}

.content-text ol,
.content-text ul {
  padding-left: 2.4rem;
}
.content-text ol li,
.content-text ul li {
  margin-bottom: 1.21875rem;
}

.list-check ul,
.section-text--list-check ul {
  padding-left: 0;
  list-style: none;
  padding-left: 0.4em;
}
.list-check ul li,
.section-text--list-check ul li {
  position: relative;
  margin-bottom: 1.21875rem;
  padding-left: 2em;
}
.list-check ul li::before,
.section-text--list-check ul li::before {
  font-weight: normal;
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\e909";
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.list-check ul li::before,
.section-text--list-check ul li::before {
  position: absolute;
  top: 0.2em;
  left: 0;
  color: #2572bd;
  font-size: 20px;
}

/* Pagination */
.pagination {
  margin-top: var(--content-element-margin);
}
.pagination ul {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: center;
}
.pagination li a,
.pagination li strong {
  min-width: calc(1.375rem + 1.5vw);
  height: calc(1.375rem + 1.5vw);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2em;
  border: 1px solid #bbc0c2;
}
@media (min-width: 1200px) {
  .pagination li a,
  .pagination li strong {
    min-width: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .pagination li a,
  .pagination li strong {
    height: 2.5rem;
  }
}
.pagination li strong {
  color: #fff;
  font-weight: 400;
  background-color: #2572bd;
}
.pagination li a {
  transition: background-color 0.4s;
  color: inherit;
  text-decoration: none;
}
@media (prefers-reduced-motion: reduce) {
  .pagination li a {
    transition: none;
  }
}
.pagination li a:focus, .pagination li a:visited {
  color: inherit;
}
.pagination li a:hover {
  color: #fff;
}
.pagination li a:hover, .pagination li a:focus, .pagination li a:active {
  text-decoration: none;
}
.pagination li a:hover {
  background-color: rgb(24.4756637168, 75.4115044248, 125.0243362832);
}

.section--bg-white {
  padding-right: var(--section-padding-inside-x-filled);
  padding-left: var(--section-padding-inside-x);
  background-color: #fff;
}
@media (min-width: 768px) {
  .section--bg-white [class^=content-] + [class^=content-] h2:first-child, .section--bg-white [class^=content-] + [class^=content-] .h2:first-child {
    margin-top: var(--article-margin);
  }
}
@media (min-width: 992px) {
  .section--bg-white .content-gallery {
    margin-right: calc(var(--section-padding-inside-x) * -1);
  }
}

.section--bg-white-full-width {
  position: relative;
}
.section--bg-white-full-width::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: -2;
  width: calc(100vw - var(--scrollbar-width));
  background-color: #fff;
  transform: translateX(-50%);
  content: "";
}
.section--bg-white,
.section--bg-white-full-width {
  padding-top: var(--article-padding-inside-y);
  padding-bottom: var(--article-padding-inside-y);
}
.section--bg-white > :last-child,
.section--bg-white-full-width > :last-child {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .mod_article + .section--bg-white,
  .mod_article + .section--bg-white-full-width {
    padding-top: var(--article-margin);
  }
}

.section--bg-white-full-width + .mod_article .section-divider-image {
  position: relative;
  margin-top: calc(var(--article-margin) * -1);
}
.section--bg-white-full-width + .mod_article .section-divider-image::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: -2;
  width: calc(100vw - var(--scrollbar-width));
  background-color: #fff;
  transform: translateX(-50%);
  content: "";
}
.section--bg-white-full-width + .mod_article .section-divider-image::after {
  bottom: 60%;
}

.section-content {
  margin-right: calc(var(--section-padding-inside-x-fill) * -1);
}

.section-text--limit-width {
  max-width: 35em;
}
.section-text--limit-width .section-text__content {
  width: 90%;
  max-width: 30em;
}

.section-text--limit-width-lg .section-text__content {
  width: 90%;
  max-width: 48.5em;
}

:root {
  --carousel-button-width: 3.75rem;
  --swiper-theme-color: #2572bd;
}

.swiper-equal .swiper-slide {
  height: auto;
}

.swiper-equal {
  height: auto;
}

.swiper:not(.swiper-initialized) .carousel-button {
  display: none;
}

.carousel-intro {
  display: flex;
  gap: 1.25rem;
  margin-bottom: var(--content-element-margin);
}
.carousel-intro > * {
  margin-bottom: 0;
}
.carousel-intro .headline-group {
  flex-grow: 1;
}

.carousel-button-wrapper {
  z-index: 1;
  display: flex;
}
.mod_newslist .carousel-button-wrapper {
  margin-top: calc(1.5rem + 3vw);
}
@media (min-width: 1200px) {
  .mod_newslist .carousel-button-wrapper {
    margin-top: 3.75rem;
  }
}
@media (max-width: 767.98px) {
  .mod_newslist .carousel-button-wrapper {
    display: none;
  }
}
@media (min-width: 1200px) {
  .mod_newslist .carousel-button-wrapper {
    margin-top: 1rem;
  }
}

.carousel-button-center {
  justify-content: center;
}

.carousel-button {
  border-radius: var(--carousel-button-width);
  transition: background-size 0.4s, color 0.4s;
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--carousel-button-width);
  height: calc(var(--carousel-button-width) * 0.565);
  font-size: 0.75em;
  background-repeat: no-repeat;
  background-size: 0 100%;
  border: 1px solid #dcdcdf;
  cursor: pointer;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-button {
    transition: none;
  }
}
.carousel-button:focus, .carousel-button:visited {
  color: #000;
}
.carousel-button:hover {
  color: #fff;
}
.carousel-button:hover, .carousel-button:focus, .carousel-button:active {
  text-decoration: none;
}
.carousel-button-prev {
  background-image: linear-gradient(to right, #2572bd 0%, #2572bd 100%);
  margin-right: 0.5rem;
  background-position: right;
}
@media (min-width: 768px) {
  .carousel-button-prev {
    margin-right: 1.25rem;
  }
}
.carousel-button-next {
  background-image: linear-gradient(to right, #2572bd 0%, #2572bd 100%);
}
.carousel-button:hover {
  background-size: 100% 100%;
}
.carousel-button.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.swiper-pagination-progressbar.swiper-pagination-horizontal {
  margin-top: calc(1.2875rem + 0.45vw);
  position: relative;
  bottom: 0;
  height: 3px;
  background-color: #bbc0c2;
  box-shadow: inset 0 1px 0 rgb(235, 240, 245), inset 0 -1px 0 rgb(235, 240, 245);
}
@media (min-width: 1200px) {
  .swiper-pagination-progressbar.swiper-pagination-horizontal {
    margin-top: 1.625rem;
  }
}

.carousel-right-mask {
  position: relative;
}
.carousel-right-mask::before {
  background-image: linear-gradient(to right, rgba(235, 240, 245, 0.2) 0%, #ebf0f5 100%);
  position: absolute;
  top: 0;
  right: var(--container-complete);
  bottom: 0;
  z-index: 2;
  background-color: rgba(235, 240, 245, 0.7);
  content: "";
}
@media (min-width: 768px) {
  .carousel-right-mask::before {
    right: max(var(--container-complete), -120px);
    left: calc(100% - var(--header-container-complete-right));
  }
}
.carousel-right-mask .carousel-right-mask__inside {
  margin-right: max(var(--container-complete), -120px);
  overflow: hidden;
}
.carousel-right-mask .carousel-right-mask__content {
  max-width: var(--container-width);
}
.carousel-right-mask .swiper {
  overflow: visible;
}

.swiper-pagination-buttons {
  margin-top: var(--content-element-margin);
}
.swiper-pagination-buttons .swiper-pagination-bullet {
  width: auto;
  height: auto;
  margin: 0 !important;
  opacity: 1;
}
.swiper-pagination-buttons .swiper-pagination-bullet-active {
  --bs-btn-color: #fff;
  --bs-btn-bg: #8a8e8d;
  --bs-btn-border-color: #8a8e8d;
  pointer-events: none;
}

/* Accordion */
.ce_accordion {
  background-color: transparent;
  box-shadow: inset 0 calc(var(--bs-border-width) * -1) 0 #2572bd;
}
.ce_accordion .toggler {
  border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  font-size: calc(1.275rem + 0.3vw);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  position: relative;
  display: flex;
  gap: 0.5em;
  width: 100%;
  padding: 0.45rem 0.5rem 0.45rem 0;
  color: #111315;
  font-weight: 800;
  line-height: 1.2;
  background-color: transparent;
}
@media (min-width: 1200px) {
  .ce_accordion .toggler {
    font-size: 1.5rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .ce_accordion .toggler {
    transition: none;
  }
}
.ce_accordion .toggler::after {
  transition: transform 0.2s ease-in-out;
  position: relative;
  top: 0.2em;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%232572bd' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  content: "";
}
@media (prefers-reduced-motion: reduce) {
  .ce_accordion .toggler::after {
    transition: none;
  }
}
.ce_accordion .accordion {
  font-size: 0.875rem;
  height: 0;
  overflow: hidden;
  line-height: 2.1428571429;
  transition-timing-function: ease;
  transition-property: height, visibility;
}
.ce_accordion .accordion > div {
  padding: 0.45rem 0.5rem 3.3rem 0;
  color: var(--bs-body-color);
  background-color: transparent;
}
.ce_accordion .accordion > div > *:last-child {
  margin-bottom: 0;
}
.ce_accordion.active .toggler {
  color: #111315;
  background-color: transparent;
}
.ce_accordion.active .toggler::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%232572bd' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.ce_accordion + .ce_accordion {
  margin-top: calc(var(--content-element-margin) * -1 + 1.875rem);
}
.ce_accordion--listing {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  box-shadow: inset 0 calc(var(--bs-border-width) * -1) 0 #bbc0c2;
}
.ce_accordion--listing:first-child {
  border-top: 1px solid #bbc0c2;
}
.ce_accordion--listing .toggler {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.ce_accordion--listing .accordion {
  font-size: 1rem;
}
.ce_accordion--listing .accordion > div {
  padding: 0 2rem 1.2rem 0;
}

/* Download */
.content-downloads ul {
  padding-left: 0;
  list-style: none;
}

.content-download + .content-download .download-element:first-child {
  margin-top: calc(var(--content-element-margin) * -0.4);
}

.download-element {
  margin-bottom: calc(var(--content-element-margin) * 0.4);
}
.download-element a {
  color: #2572bd;
  text-decoration: none;
  display: flex;
  width: fit-content;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}
.download-element a:focus, .download-element a:visited {
  color: #2572bd;
}
.download-element a:hover {
  color: rgb(24.4756637168, 75.4115044248, 125.0243362832);
}
.download-element a:hover, .download-element a:focus, .download-element a:active {
  text-decoration: none;
}
.download-element a::before {
  font-weight: normal;
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\e901";
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.download-element a::before {
  position: relative;
  top: -0.1em;
  margin-right: 0.6em;
  font-weight: 800;
  font-size: 1.1em;
  line-height: inherit;
}
.download-element .size {
  position: relative;
  top: 0.375em;
  padding-left: 0.8em;
  font-size: 0.8em;
  white-space: nowrap;
  opacity: 0.8;
}

.enclosure {
  padding-left: 0;
  list-style: none;
}

/* Gallery */
.content-gallery .content-gallery > ul li.col_first {
  clear: none;
}
.content-gallery ul {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  --bs-gutter-y: var(--bs-gutter-x);
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.content-gallery li {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
  flex: 0 0 auto;
  width: 50%;
}
.content-gallery li figure {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .content-gallery--cols-1 li {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .content-gallery--cols-2 li {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .content-gallery--cols-3 li {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
@media (min-width: 768px) {
  .content-gallery--cols-4 li {
    flex: 0 0 auto;
    width: 25%;
  }
}
@media (min-width: 768px) {
  .content-gallery--cols-5 li {
    flex: 0 0 auto;
    width: 20%;
  }
}
@media (min-width: 768px) {
  .content-gallery--cols-6 li {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}
@media (min-width: 768px) {
  .content-gallery--cols-7 li {
    flex: 0 0 auto;
    width: 14.28571429%;
  }
}
@media (min-width: 768px) {
  .content-gallery--cols-8 li {
    flex: 0 0 auto;
    width: 12.5%;
  }
}
@media (min-width: 768px) {
  .content-gallery--cols-9 li {
    flex: 0 0 auto;
    width: 11.11111111%;
  }
}
@media (min-width: 768px) {
  .content-gallery--cols-10 li {
    flex: 0 0 auto;
    width: 10%;
  }
}
@media (min-width: 768px) {
  .content-gallery--cols-11 li {
    flex: 0 0 auto;
    width: 9.09090909%;
  }
}
@media (min-width: 768px) {
  .content-gallery--cols-12 li {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
}

.content-table table,
.content-text table {
  line-height: 1.5;
}

.table-th-last tbody th {
  font-weight: normal;
}
.table-th-last tbody th ~ td:last-child {
  font-weight: bold;
  text-align: right;
}

.ce_rsce_banner:last-child {
  margin-bottom: 0;
}

.banner__visual {
  margin-bottom: 2rem;
}
.banner__visual-inside {
  --size-square-cover: var(--section-padding-inside-x);
  clip-path: polygon(0 0, 100% 0, 100% 100%, var(--size-square-cover) 100%, var(--size-square-cover) calc(100% - var(--size-square-cover)), 0% calc(100% - var(--size-square-cover)));
  position: relative;
  margin-right: var(--container-complete);
}
.banner__visual-inside img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 992px) {
  .banner__visual-inside {
    --size-square-cover: calc(var(--section-padding-inside-x) * 1.2);
  }
}
.banner__content-subheadline {
  font-size: calc(1.3125rem + 0.75vw);
  margin-bottom: 0.9em;
  margin-left: var(--section-padding-inside-x);
  line-height: 1.5;
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  .banner__content-subheadline {
    font-size: 1.875rem;
  }
}
.banner__content-headline {
  margin-bottom: 0.6em;
  color: #2572bd;
  font-weight: 800;
  font-size: clamp(40px, 7.6vw, 110px);
  line-height: 0.8181818182;
  text-transform: uppercase;
}
.banner__content-text {
  font-size: 1.25rem;
  margin-left: var(--section-padding-inside-x);
  line-height: 1.5;
  letter-spacing: 0;
  hyphenate-limit-chars: 10 4 4;
}
.banner__content-text > *:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .banner {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    flex-direction: row-reverse;
  }
  .banner__visual, .banner__content {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
  .banner__visual {
    flex: 0 0 auto;
    width: 58.33333333%;
    align-self: flex-start;
    margin-bottom: 0;
  }
  .banner__visual-inside {
    height: 100%;
    margin-right: calc(var(--container-padding-x) * -0.5);
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .banner__visual-inside {
    border-right: var(--container-padding-x-sm) solid rgb(235, 240, 245);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .banner__visual-inside {
    margin-right: max(var(--container-complete), -120px);
    border-right: 0;
  }
}
@media (min-width: 768px) and (min-width: 1400px) {
  .banner__visual-inside {
    min-height: 700px;
  }
}
@media (min-width: 768px) {
  .banner__visual .content-gallery,
  .banner__visual figure {
    margin: 0;
  }
  .banner__visual img {
    position: relative;
    z-index: -1;
    max-height: 700px;
  }
  .banner__content {
    flex: 0 0 auto;
    width: 41.66666667%;
    position: relative;
    z-index: 2;
    align-self: flex-end;
    padding-top: 11%;
  }
  .banner__content-subheadline {
    margin-right: -2em;
  }
  .banner__content-headline {
    margin-right: -2.15em;
  }
}

.banner-subpage__title {
  line-height: 1;
  text-transform: uppercase;
}
.banner-subpage__headline {
  max-width: 13em;
  margin-bottom: 0.65em;
  line-height: 1.045;
}
.banner-subpage__text {
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0;
}
.banner-subpage--type-1 {
  padding-top: 125px;
}
.banner-subpage--type-1 .banner-subpage__title {
  font-size: calc(1.3125rem + 0.75vw);
  margin-bottom: 0.85em;
  margin-left: var(--section-padding-inside-x);
}
@media (min-width: 1200px) {
  .banner-subpage--type-1 .banner-subpage__title {
    font-size: 1.875rem;
  }
}
.banner-subpage--type-1 .banner-subpage__headline {
  color: #2572bd;
}
.banner-subpage--type-1 .banner-subpage__text {
  max-width: 33em;
  margin-left: var(--section-padding-inside-x);
}
.banner-subpage--type-1 .banner-subpage__text > :last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .banner-subpage--type-1 {
    padding-top: calc(2.09375rem + 10.125vw);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .banner-subpage--type-1 {
    padding-top: 9.6875rem;
  }
}
.banner-subpage--type-2 {
  position: relative;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: clamp(200px, 30vw, 590px);
  padding-top: 125px;
  padding-bottom: var(--banner-subpage-gap-bottom);
  color: #fff;
}
.banner-subpage--type-2::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: -2;
  width: calc(100vw - var(--scrollbar-width));
  background-color: #2572bd;
  transform: translateX(-50%);
  content: "";
}
.banner-subpage--type-2 .banner-subpage__title {
  margin-bottom: 1.15em;
  font-size: 36px;
}
.banner-subpage--type-2 .banner-subpage__title:last-child {
  margin-bottom: 0;
}
.banner-subpage--type-2 .banner-subpage__headline {
  color: inherit;
}
.banner-subpage--type-2 .banner-subpage__text {
  max-width: 40em;
}
.banner-subpage--type-2 .banner-subpage__text > :last-child {
  margin-bottom: 0;
}
.banner-subpage--type-2::after {
  background-image: url("../../../images/bg-pattern.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 767.98px) {
  .banner-subpage--type-2 .banner-subpage__content {
    margin-bottom: 90px;
  }
  body:not(:has(.section-intro:first-child)) .banner-subpage--type-2 .banner-subpage__content {
    margin-bottom: calc(125px - var(--banner-subpage-gap-bottom));
  }
  .banner-subpage--type-2.has-section-intro .banner-subpage__content {
    margin-bottom: calc(125px - var(--banner-subpage-gap-bottom));
  }
}
@media (min-width: 768px) {
  .banner-subpage--type-2 {
    padding-top: calc(2rem + 9vw);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .banner-subpage--type-2 {
    padding-top: 8.75rem;
  }
}
@media (min-width: 768px) {
  .banner-subpage--type-2 .banner-subpage__title {
    font-size: calc(1.425rem + 2.1vw);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .banner-subpage--type-2 .banner-subpage__title {
    font-size: 3rem;
  }
}
@media (min-width: 1400px) {
  .banner-subpage--type-2 {
    padding-top: 9.75rem;
  }
}

.box-intro__visual-inside {
  position: relative;
}
.box-intro__visual-inside img {
  --size-square-cover: var(--section-padding-inside-x);
  clip-path: polygon(0 0, 100% 0, 100% 100%, var(--size-square-cover) 100%, var(--size-square-cover) calc(100% - var(--size-square-cover)), 0% calc(100% - var(--size-square-cover)));
}
@media (min-width: 768px) {
  .box-intro__headline {
    margin-bottom: 0.5rem;
  }
  .box-intro__content {
    margin-bottom: calc(1.625rem + 4.5vw);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .box-intro__content {
    margin-bottom: 5rem;
  }
}
@media (min-width: 768px) {
  .event-list-home .box-intro {
    max-width: 21em;
  }
  .event-list-home .box-intro__visual {
    margin-left: max(var(--container-complete), -130px);
    text-align: right;
    border-left: 10px solid rgb(235, 240, 245);
  }
  .event-list-home .box-intro__visual img {
    width: 100%;
  }
  .event-list-home .box-intro__content-text {
    max-width: 18em;
  }
}

.call-to-action {
  --visual-offset: 0;
  --padding-y: 3.75rem;
  --padding-x: var(--column-one);
  position: relative;
  z-index: 1;
}
.call-to-action::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: -2;
  width: calc(100vw - var(--scrollbar-width));
  background-color: rgb(235, 240, 245);
  transform: translateX(-50%);
  content: "";
}
.call-to-action__wrapper {
  padding: var(--padding-y) var(--padding-x) 0 var(--padding-x);
  color: #fff;
  background-color: #012e4b;
  border-bottom: var(--visual-offset) solid rgb(235, 240, 245);
}
.call-to-action__content {
  margin-bottom: 1.21875rem;
}
.call-to-action__headline {
  margin-bottom: 1em;
  font-size: 36px;
  line-height: 1;
  text-transform: uppercase;
}
.call-to-action__text {
  position: relative;
}
.call-to-action__text > *:last-child {
  margin-bottom: 0;
}
.call-to-action__link {
  color: #fff;
  text-decoration: none;
  margin-left: 0.5em;
  font-size: 0.75em;
}
.call-to-action__link:focus, .call-to-action__link:visited {
  color: #fff;
}
.call-to-action__link:hover {
  color: #8a8e8d;
}
.call-to-action__link:hover, .call-to-action__link:focus, .call-to-action__link:active {
  text-decoration: none;
}
.call-to-action__link i {
  transition: transform 0.4s;
  display: inline-block;
}
@media (prefers-reduced-motion: reduce) {
  .call-to-action__link i {
    transition: none;
  }
}
.call-to-action__link:hover i {
  transform: translateX(0.25em);
}
.call-to-action__visual {
  position: relative;
  z-index: 1;
  margin-bottom: calc(var(--visual-offset) * -1);
  margin-left: auto;
}
.call-to-action__visual::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: -1;
  width: calc(100% + var(--padding-x) * 3);
  height: 35%;
  background: rgb(235, 240, 245);
  transform: translateX(-50%);
  content: "";
}
.call-to-action::after {
  top: 50%;
}
.section--bg-white .call-to-action {
  margin-bottom: 0;
}
.section--bg-white .call-to-action:last-child {
  margin-top: var(--article-margin);
  margin-bottom: calc(var(--article-padding-inside-y) * -1);
}
@media (max-width: 767.98px) {
  .call-to-action--bg-type-2 {
    max-width: 85%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .call-to-action__wrapper {
    min-height: 296px;
    padding-bottom: var(--padding-y);
  }
  .call-to-action__wrapper-row {
    position: relative;
    display: flex;
    align-items: center;
  }
  .call-to-action__content {
    position: relative;
    z-index: 2;
    flex: 0 0 var(--content-width);
    margin-bottom: 0;
  }
  .call-to-action__headline {
    font-size: calc(1.425rem + 2.1vw);
    margin-right: -2em;
    margin-bottom: 0.725em;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .call-to-action__headline {
    font-size: 3rem;
  }
}
@media (min-width: 768px) {
  .call-to-action__visual {
    top: -1.25rem;
    bottom: calc(-2rem - 9vw);
    position: absolute;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: calc(100% - var(--content-width));
    margin-bottom: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .call-to-action__visual {
    bottom: -8.75rem;
  }
}
@media (min-width: 768px) {
  .call-to-action__visual::after {
    display: none;
  }
  .call-to-action--bg-type-1 {
    --content-width: 62%;
  }
  .call-to-action--bg-type-1 .call-to-action__visual img {
    max-width: 140%;
    transform: translate(3%, 13%);
  }
  .call-to-action--bg-type-2 {
    --content-width: max(42%, 23em);
  }
  .call-to-action--bg-type-2 .call-to-action__visual img {
    max-width: 80%;
  }
}

.carousel-image-text {
  --image-text-offset: calc(var(--container-padding-x-half) * 0.5);
}
@media (max-width: 575.98px) {
  .carousel-image-text {
    margin-right: calc(var(--image-text-offset) * -1);
  }
}
@media (min-width: 992px) {
  .carousel-image-text .carousel-intro {
    padding-right: var(--column-one);
    padding-left: var(--column-one);
  }
}

.box-image-text-carousel__visual {
  margin-bottom: 3rem;
}
.box-image-text-carousel__visual-inside {
  position: relative;
}
.box-image-text-carousel__visual-inside img {
  width: 100%;
}
.box-image-text-carousel__visual-text {
  padding-top: calc(1.29375rem + 0.525vw);
  padding-bottom: calc(1.29375rem + 0.525vw);
  padding-right: calc(1.3125rem + 0.75vw);
  padding-left: calc(1.3125rem + 0.75vw);
  font-size: calc(1.275rem + 0.3vw);
  position: absolute;
  right: 0;
  bottom: 12%;
  z-index: 1;
  width: 9.5em;
  color: #fff;
  font-weight: 700;
  line-height: 1.25;
  text-transform: uppercase;
  background-color: #2572bd;
}
@media (min-width: 1200px) {
  .box-image-text-carousel__visual-text {
    padding-top: 1.6875rem;
  }
}
@media (min-width: 1200px) {
  .box-image-text-carousel__visual-text {
    padding-bottom: 1.6875rem;
  }
}
@media (min-width: 1200px) {
  .box-image-text-carousel__visual-text {
    padding-right: 1.875rem;
  }
}
@media (min-width: 1200px) {
  .box-image-text-carousel__visual-text {
    padding-left: 1.875rem;
  }
}
@media (min-width: 1200px) {
  .box-image-text-carousel__visual-text {
    font-size: 1.5rem;
  }
}
.box-image-text-carousel__headline {
  margin-bottom: 0.6em;
  line-height: 1.3333333333;
}
.box-image-text-carousel__text {
  font-size: 0.875rem;
  margin-bottom: 1.21875rem;
  line-height: 2.1428571429;
}
.box-image-text-carousel__text:last-child {
  margin-bottom: 0;
}
.box-image-text-carousel__text > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .box-image-text-carousel__visual-inside {
    padding-right: var(--image-text-offset);
  }
  .box-image-text-carousel__content {
    padding-right: var(--image-text-offset);
  }
}
@media (min-width: 576px) {
  .box-image-text-carousel {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    height: 100%;
  }
  .box-image-text-carousel > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
  .box-image-text-carousel__visual {
    flex: 0 0 auto;
    width: 45.83333333%;
    margin-bottom: 0;
  }
  .box-image-text-carousel__visual-inside {
    height: 100%;
  }
  .box-image-text-carousel__visual img {
    height: 100%;
    object-fit: cover;
  }
  .box-image-text-carousel__visual-text {
    right: calc(var(--bs-gutter-x) * -0.5);
  }
  .box-image-text-carousel__content {
    flex: 1 1 0;
    max-width: 100%;
    padding-top: calc(1.4375rem + 2.25vw);
    padding-bottom: calc(1.4375rem + 2.25vw);
    padding-left: 30px;
  }
}
@media (min-width: 576px) and (min-width: 1200px) {
  .box-image-text-carousel__content {
    padding-top: 3.125rem;
  }
}
@media (min-width: 576px) and (min-width: 1200px) {
  .box-image-text-carousel__content {
    padding-bottom: 3.125rem;
  }
}
@media (min-width: 576px) {
  .box-image-text-carousel__headline {
    margin-bottom: 0.4em;
  }
  .box-image-text-carousel__text {
    max-width: 25em;
  }
}
@media (min-width: 768px) {
  .box-image-text-carousel__visual {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .box-image-text-carousel__content-inside {
    max-width: 27.5em;
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .box-image-text-carousel__visual-text {
    right: -3.125rem;
  }
  .box-image-text-carousel__content {
    padding-right: var(--column-one);
  }
}

@media (min-width: 992px) {
  .boxes-download-wrapper .carousel-intro {
    margin-right: var(--column-one);
    margin-left: var(--column-one);
  }
  .boxes-download-wrapper .carousel-right-mask__inside {
    margin-left: var(--column-one);
  }
  .boxes-download-wrapper .carousel-right-mask__content {
    padding-right: var(--column-one);
  }
}

.boxes-download .content-download {
  margin-bottom: 0;
}

.box-download {
  transition: background-color 0.4s;
  color: #fff;
  text-decoration: none;
  font-size: calc(1.425rem + 2.1vw);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.2em;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  background-color: #012e4b;
  aspect-ratio: 1;
}
@media (prefers-reduced-motion: reduce) {
  .box-download {
    transition: none;
  }
}
.box-download:focus, .box-download:visited {
  color: #fff;
}
.box-download:hover {
  color: #fff;
}
.box-download:hover, .box-download:focus, .box-download:active {
  text-decoration: none;
}
@media (min-width: 1200px) {
  .box-download {
    font-size: 3rem;
  }
}
.box-download__icon {
  display: none;
  flex-basis: 100%;
  text-align: center;
}
.box-download__icon::before {
  font-weight: normal;
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\e901";
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.box-download:hover {
  background-color: #2572bd;
}
.box-download:hover .box-download__icon {
  display: block;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .job-list__filters {
    margin-right: calc(var(--column-one) * -1);
    margin-left: calc(var(--column-one) * -1);
  }
}
@media (max-width: 767.98px) {
  .job-list {
    margin-top: 80px;
  }
}
@media (min-width: 768px) {
  .job-list {
    margin-right: var(--column-one);
    margin-left: var(--column-one);
  }
}

.job-item {
  height: 100%;
}
.job-item__visual {
  margin-bottom: var(--content-element-margin);
}
.job-item__visual img {
  --size-square-cover: var(--section-padding-inside-x);
  clip-path: polygon(0 0, 100% 0, 100% 100%, var(--size-square-cover) 100%, var(--size-square-cover) calc(100% - var(--size-square-cover)), 0% calc(100% - var(--size-square-cover)));
  width: 100%;
}
.job-item__category {
  margin-bottom: 1em;
}
.job-item__text {
  font-size: 0.875rem;
  line-height: 2.1428571429;
}
.job-item__text:not(:last-child) {
  margin-bottom: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  .job-item__text:not(:last-child) {
    margin-bottom: 1.875rem;
  }
}
.job-item__text > *:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .job-item {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .job-item > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
    flex: 0 0 auto;
    width: 50%;
  }
  .job-item__visual {
    align-self: flex-start;
    margin-bottom: 0;
  }
  .job-item__content {
    padding-top: calc(1.4375rem + 2.25vw);
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .job-item__content {
    padding-top: 3.125rem;
  }
}
@media (min-width: 768px) {
  .job-item__content-inside {
    max-width: 23.5em;
    margin-left: auto;
  }
}

.section-text-image + .section-text-image {
  margin-top: var(--article-margin);
}
.section-text-image__visual {
  margin-bottom: var(--content-element-margin);
}
.section-text-image__visual img {
  --size-square-cover: var(--section-padding-inside-x);
  clip-path: polygon(0 0, 100% 0, 100% 100%, var(--size-square-cover) 100%, var(--size-square-cover) calc(100% - var(--size-square-cover)), 0% calc(100% - var(--size-square-cover)));
}
.section-text-image__text {
  font-size: 0.875rem;
}
.section-text-image__text > *:last-child {
  margin-bottom: 0;
}
.section-text-image__text:not(:last-child) {
  margin-bottom: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  .section-text-image__text:not(:last-child) {
    margin-bottom: 1.875rem;
  }
}
.section-text-image__link .content-download {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .section-text-image {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    justify-content: space-between;
  }
  .section-text-image > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
  .section-text-image__visual {
    flex: 0 0 auto;
    width: 41.66666667%;
    margin-bottom: 0;
  }
  .section-text-image__visual img {
    height: 100%;
    object-fit: cover;
  }
  .section-text-image__content {
    flex: 0 0 auto;
    width: 50%;
    padding-top: calc(1.425rem + 2.1vw);
    padding-bottom: calc(1.425rem + 2.1vw);
  }
}
@media (min-width: 576px) and (min-width: 1200px) {
  .section-text-image__content {
    padding-top: 3rem;
  }
}
@media (min-width: 576px) and (min-width: 1200px) {
  .section-text-image__content {
    padding-bottom: 3rem;
  }
}
@media (min-width: 576px) {
  .section-text-image__text {
    max-width: 27.5em;
    line-height: 2.1428571429;
  }
  .section-text-image--right {
    flex-direction: row-reverse;
  }
  .section-text-image--right .section-text-image__content-inside {
    padding-left: var(--column-one);
  }
  .section-text-image--center .section-text-image__content {
    align-self: center;
  }
}

.section-intro {
  margin-bottom: var(--content-element-margin-xl);
}
.section-intro__visual {
  margin-bottom: var(--content-element-margin-xl);
}
.section-intro__visual img {
  --size-square-cover: clamp(4.375rem, 10vw, 7.5rem);
  clip-path: polygon(0 0, 100% 0, 100% 100%, var(--size-square-cover) 100%, var(--size-square-cover) calc(100% - var(--size-square-cover)), 0% calc(100% - var(--size-square-cover)));
  width: 100%;
}
.section-intro__headline {
  margin-bottom: 0.75em;
}
.section-intro__text > *:last-child {
  margin-bottom: 0;
}
.section-intro__text a {
  --underline-size: 1px;
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  background-position: 0 1.2em;
  background-size: 100% 1px;
  transition: background-size 0.8s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
}
.section-intro__text a:hover {
  animation: link-underline-visible-animate 0.4s;
}
.mod_article:first-child .section-intro.has-banner-type-2:first-child .section-intro__visual {
  margin-top: calc((var(--container-margin-top) + var(--banner-subpage-gap-bottom)) * -1);
}
body:has(.banner-subpage--type-2) .mod_article:first-child .section-intro:first-child .section-intro__visual {
  margin-top: calc((var(--container-margin-top) + var(--banner-subpage-gap-bottom)) * -1);
}
@media (min-width: 768px) {
  .section-intro {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    flex-direction: row-reverse;
  }
  .section-intro__visual, .section-intro__content {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
    flex: 0 0 auto;
    width: 50%;
  }
  .section-intro__visual {
    margin-bottom: 0;
  }
  .section-intro__visual img {
    height: 100%;
    object-fit: cover;
  }
}
@media (min-width: 992px) {
  .section-intro__visual {
    flex: 0 0 auto;
    width: 58.33333333%;
    align-self: flex-start;
  }
  .section-intro__content {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
}

.ce_rsce_team:last-child {
  margin-bottom: 0;
}

.team {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  --bs-gutter-y: 3.2rem;
}
.team > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 768px) {
  .team > * {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .team {
    --bs-gutter-y: 5.4rem;
  }
}

.person {
  --bs-gutter-x: 1.2rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  flex-wrap: nowrap;
}
.person__visual, .person__content {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.person__visual {
  flex: 0 0 auto;
  width: auto;
}
.person__visual img {
  border-radius: 50%;
}
.person__content {
  flex: 1 1 0;
  max-width: 100%;
  padding-top: 1.0625rem;
  line-height: 1.5;
}
.person__content-intro {
  margin-bottom: 1.25rem;
  font-weight: 700;
}
.person__content-intro__department {
  font-size: 1.25rem;
  line-height: 1.2;
}
.person__content-name {
  font-weight: 700;
}
.person__content-name:last-child {
  margin-bottom: 0.625rem;
}
.person__content .email {
  --underline-size: 1px;
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  background-position: 0 1.2em;
  background-size: 100% 1px;
  transition: background-size 0.8s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
  margin-bottom: 1.25rem;
  display: inline-block;
}
.person__content .email:hover {
  animation: link-underline-visible-animate 0.4s;
}

.quote {
  font-size: calc(1.425rem + 2.1vw);
  margin-top: var(--content-element-margin-lg);
  margin-bottom: var(--content-element-margin-lg);
  padding-right: var(--column-one);
  padding-left: var(--column-one);
  color: #d1d1d1;
  font-weight: 600;
  font-style: italic;
  line-height: 1;
  letter-spacing: 0.02em;
}
@media (min-width: 1200px) {
  .quote {
    font-size: 3rem;
  }
}
@media (min-width: 992px) {
  .section--bg-white .quote {
    margin-right: -1.5em;
    padding-right: 0;
  }
}

/* Page menu and mobile manu */
.header-navigation {
  --font-size: clamp(12px, 1vw, 14px);
  --nav-margin: calc(var(--font-size) * 2);
  transition: top 0.4s ease-in-out;
  position: fixed;
  top: -100%;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: var(--font-size);
  line-height: 1.2;
  background: url("../../../images/bg-pattern.png") center #2572bd no-repeat;
  background-size: cover;
}
@media (prefers-reduced-motion: reduce) {
  .header-navigation {
    transition: none;
  }
}
.header-navigation__inside {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: calc(var(--header-height) + 3rem);
}
.header-navigation__content {
  flex: 1 1 0;
  max-width: 100%;
  overflow-y: auto;
}
.header-navigation__content a {
  color: #fff;
  text-decoration: none;
}
.header-navigation__content a:focus, .header-navigation__content a:visited {
  color: #fff;
}
.header-navigation__content a:hover {
  color: #bda013;
}
.header-navigation__content a:hover, .header-navigation__content a:focus, .header-navigation__content a:active {
  text-decoration: none;
}
.header-navigation__main-menu strong {
  font-weight: 400;
}
.header-navigation__main-menu strong,
.header-navigation__main-menu a {
  display: inline-block;
  margin-bottom: calc(var(--font-size) * 2.2);
}
.header-navigation__main-menu strong.active,
.header-navigation__main-menu a.active {
  color: #bda013;
}
.header-navigation__main-menu ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.header-navigation__main-menu ul.level_1 {
  margin-top: calc(var(--nav-margin) * -1);
}
.header-navigation__main-menu ul.level_1 > li {
  padding-top: var(--nav-margin);
}
.header-navigation__main-menu ul.level_1 > li > a,
.header-navigation__main-menu ul.level_1 > li > strong {
  font-weight: 800;
  font-size: 2.5em;
}
.header-navigation__main-menu ul.level_2 {
  margin-left: 2em;
}
.header-navigation__main-menu ul.level_2 > li > a,
.header-navigation__main-menu ul.level_2 > li > strong {
  font-size: 1.425em;
}
.header-navigation__main-menu ul.level_2 > li:not(:last-child) ul.level_3 {
  margin-bottom: 1em;
}
.header-navigation__main-menu ul.level_2 strong::before {
  position: relative;
  top: -0.1em;
  margin-right: 0.625rem;
  content: "—";
}
.header-navigation__main-menu ul.level_3 {
  margin-left: 3.5em;
}
.header-navigation__mobile-menu {
  margin-top: var(--nav-margin);
  font-size: 1.425em;
}
.header-navigation__mobile-menu ul {
  margin-bottom: 0;
}
.header-navigation__mobile-menu ul > li:not(:last-child) {
  margin-bottom: calc(var(--font-size) * 2.2);
}
.header-navigation__footer {
  margin-top: 3.2em;
  padding-top: 3.2em;
  padding-bottom: 3.2em;
  text-align: center;
  border-top: 1px solid #fff;
}
@media (max-width: 767.98px) {
  .header-navigation {
    padding-top: calc(var(--header-height));
  }
  .header-navigation__inside {
    padding-top: 3rem;
    overflow-y: auto;
  }
  .header-navigation__content {
    overflow: visible;
  }
}
@media (min-width: 768px) {
  .header-navigation__footer {
    margin-right: var(--column-one);
    margin-left: var(--column-one);
  }
  .header-navigation__content {
    display: flex;
    flex-direction: column;
    padding-right: var(--column-one);
    padding-left: var(--column-one);
  }
  .header-navigation__main-menu {
    max-width: 64em;
    margin: auto !important;
  }
  .header-navigation__main-menu ul.level_1 > * {
    display: inline-block;
    float: left;
    width: 33.333%;
    padding-right: 1rem;
  }
  .header-navigation__main-menu ul.level_1 > *.last {
    float: right;
  }
  .header-navigation__main-menu ul.level_2 {
    margin-left: 0;
  }
  .header-navigation__main-menu ul.level_3 {
    margin-top: 0.5rem;
  }
  .header-navigation__mobile-menu {
    display: none;
  }
}
@media (min-width: 1200px) {
  .header-navigation__content-menu {
    padding-left: var(--column-one);
  }
}

.header-contact__address {
  margin-bottom: 0.5em;
}
.header-contact__links {
  --bs-gutter-x: 3em;
  display: flex;
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
  justify-content: center;
}
.header-contact__links > * {
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
}
.header-contact__links > * {
  position: relative;
}
.header-contact__links > *::after {
  position: absolute;
  top: 0.1em;
  right: 0;
  bottom: 0.1em;
  width: 1px;
  background-color: #fff;
  content: "";
}
.header-contact__links > *:last-child::after {
  content: none;
}
.header-contact__links a {
  color: inherit;
  text-decoration: none;
}
.header-contact__links a:focus, .header-contact__links a:visited {
  color: inherit;
}
.header-contact__links a:hover {
  color: inherit;
}
.header-contact__links a:hover, .header-contact__links a:focus, .header-contact__links a:active {
  text-decoration: none;
}
.header-contact__links-phone a {
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  transition: background-size 1.2s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
}
.header-contact__links-phone a:hover, .header-contact__links-phone a.active {
  text-decoration: none;
  background-position: 0 1.2em;
  background-size: 100% 1px;
}
.header-contact__links-email a {
  --underline-size: 1px;
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  background-position: 0 1.2em;
  background-size: 100% 1px;
  transition: background-size 0.8s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
}
.header-contact__links-email a:hover {
  animation: link-underline-visible-animate 0.4s;
}

body.menu-active {
  --scrollbar-width: 0px;
  overflow: hidden;
}
body.menu-active .header-navigation {
  top: 0;
}

/* Mobile menu trigger
HTML markup for menu trigger
<div class="mobile_menu_trigger__inside">
    <div class="mobile_menu_trigger__content">Mobile menu</div>
    <div class="mobile_menu_trigger__hamburger"><i></i></div>
</div>
*/
.menu-trigger {
  z-index: 9;
  text-align: center;
  cursor: pointer;
}
.menu-trigger__inside {
  transition: padding-top 0.4s, padding-bottom 0.4s, background-color 0.4s;
  padding-right: calc(1.3375rem + 1.05vw);
  padding-left: calc(1.3375rem + 1.05vw);
  padding-top: calc(1.36875rem + 1.425vw);
  padding-bottom: calc(1.36875rem + 1.425vw);
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #2572bd;
}
@media (prefers-reduced-motion: reduce) {
  .menu-trigger__inside {
    transition: none;
  }
}
@media (min-width: 1200px) {
  .menu-trigger__inside {
    padding-right: 2.125rem;
  }
}
@media (min-width: 1200px) {
  .menu-trigger__inside {
    padding-left: 2.125rem;
  }
}
@media (min-width: 1200px) {
  .menu-trigger__inside {
    padding-top: 2.4375rem;
  }
}
@media (min-width: 1200px) {
  .menu-trigger__inside {
    padding-bottom: 2.4375rem;
  }
}
.menu-trigger__content {
  display: none;
  font-weight: 700;
  text-transform: uppercase;
}
.menu-trigger__hamburger {
  --trigger-width: 28px;
  --trigger-height: 4px;
  --trigger-space: 6px;
  width: var(--trigger-width);
  padding: 0;
  vertical-align: middle;
  background: transparent;
}
.menu-trigger__hamburger::after, .menu-trigger__hamburger::before,
.menu-trigger__hamburger i {
  display: block;
  height: var(--trigger-height);
  margin: var(--trigger-space) 0;
  background-color: #fff;
  border-radius: 2px;
  transition: transform 0.4s ease-in-out;
  content: "";
}
.menu-trigger__hamburger::before {
  margin-top: 0;
}
.menu-trigger__hamburger::after {
  margin-bottom: 0;
}
.menu-trigger__hamburger:not(:first-child) {
  margin-left: 4px;
}
@media (min-width: 768px) {
  .menu-trigger__hamburger {
    --trigger-width: 18px;
    --trigger-height: 2px;
    --trigger-space: 3px;
  }
}
body.menu-active .menu-trigger__inside {
  background-color: transparent;
}
body.menu-active .menu-trigger .menu-trigger__hamburger::before {
  transform: translateY(calc(var(--trigger-height) + var(--trigger-space))) rotate(135deg);
}
body.menu-active .menu-trigger .menu-trigger__hamburger::after {
  transform: translateY(calc((var(--trigger-height) + var(--trigger-space)) * -1)) rotate(-135deg);
}
body.menu-active .menu-trigger .menu-trigger__hamburger i {
  transform: scale(0);
}
@media (min-width: 768px) {
  body:not(.menu-active) .sticky .menu-trigger__inside {
    padding-top: calc(1.28125rem + 0.375vw);
    padding-bottom: calc(1.28125rem + 0.375vw);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  body:not(.menu-active) .sticky .menu-trigger__inside {
    padding-top: 1.5625rem;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  body:not(.menu-active) .sticky .menu-trigger__inside {
    padding-bottom: 1.5625rem;
  }
}
@media (min-width: 768px) {
  .menu-trigger__content {
    margin-left: 1rem;
    display: block;
  }
}

.mod_search .formbody {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0.609375rem;
}
.mod_search .widget-text {
  flex: 1;
  margin-bottom: 0;
}
.mod_search .widget-submit {
  margin-top: 0;
}
.mod_search .text {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.mod_search .submit {
  width: 9.375rem;
  height: 100%;
  padding: 0;
  line-height: 1;
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.mod_search .widget-radio {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  margin-top: 0.609375rem;
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .mod_search .widget-radio {
    flex-direction: row;
  }
}
.mod_search h3, .mod_search .h3 {
  margin-bottom: 0;
}
.mod_search h3 .relevance, .mod_search .h3 .relevance {
  color: rgb(51.2236842105, 57.25, 63.2763157895);
  font-weight: 400;
  font-size: 80%;
}
.mod_search p.header {
  margin-bottom: 0;
  padding: 0.609375rem 1rem 0 1rem;
  color: #c5c5c5;
  border-top: 1px solid #bbc0c2;
}
.mod_search p.info {
  padding: 0 1rem 0.609375rem 1rem;
  color: #c5c5c5;
  border-bottom: 1px solid #bbc0c2;
}
.mod_search p.context {
  margin-bottom: 0;
}
.mod_search p.context .highlight {
  background: #ffe680;
}
.mod_search p.url {
  color: rgb(51.2236842105, 57.25, 63.2763157895);
}
.mod_search > div {
  padding-top: 1.21875rem;
  border-bottom: 1px solid #e9ecef;
}
.mod_search > div.pagination {
  border-bottom: 0;
}
.mod_search p.info + div {
  padding-top: 0;
}

.event-list-home {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  --bs-gutter-y: 1rem;
}
.mod_article:last-child .event-list-home {
  margin-bottom: 0;
}
.event-list-home__intro, .event-list-home__listing {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.event-list-home__listing-inside {
  padding: calc(1.375rem + 1.5vw);
  background-color: #fff;
}
@media (min-width: 1200px) {
  .event-list-home__listing-inside {
    padding: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .event-list-home__listing-inside {
    padding-bottom: 75px;
  }
}
.event-list-home .mod_eventlist {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .event-list-home {
    --padding-y: 3.7rem;
  }
  .event-list-home__intro {
    flex: 0 0 auto;
    width: 33.33333333%;
    padding-top: calc(var(--padding-y) * 0.8);
  }
  .event-list-home__listing {
    flex: 1 1 0;
    max-width: 100%;
  }
  .event-list-home__listing-inside {
    position: relative;
    padding: var(--padding-y) 0;
  }
  .event-list-home__listing-inside::before {
    position: absolute;
    inset: 0 var(--container-complete) 0 calc(var(--section-padding-inside-x) * -1);
    z-index: -1;
    background: #fff;
    content: "";
  }
}

.member-catalog-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.member-catalog-filter > * {
  flex: 1 1 0;
  max-width: 100%;
}
@media (min-width: 768px) {
  .mod_article:first-child > .mod_app_member_catalog .member-catalog-filter {
    margin-top: calc(-1.4375rem - 2.25vw);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .mod_article:first-child > .mod_app_member_catalog .member-catalog-filter {
    margin-top: -3.125rem;
  }
}

.member-catalog-legend {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #bbc0c2;
}
.member-catalog-legend::before {
  font-weight: normal;
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  content: "\e908";
  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.5em;
}
.member-catalog-legend::before {
  color: #012e4b;
}

.member-catalog-item {
  padding-top: calc(1.3125rem + 0.75vw);
  padding-bottom: calc(1.3125rem + 0.75vw);
  position: relative;
  display: flex;
  flex-flow: column wrap;
  gap: 15px 30px;
  padding-left: 2em;
  line-height: 1.5;
  border-bottom: 1px solid #bbc0c2;
}
@media (min-width: 1200px) {
  .member-catalog-item {
    padding-top: 1.875rem;
  }
}
@media (min-width: 1200px) {
  .member-catalog-item {
    padding-bottom: 1.875rem;
  }
}
.member-catalog-item__instructor {
  position: absolute;
  left: 0;
  color: #012e4b;
}
.member-catalog-item__name {
  font-weight: 700;
}
@media (min-width: 768px) {
  .member-catalog-item {
    flex-flow: row nowrap;
  }
  .member-catalog-item > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

.news-list-carousel .swiper-slide {
  width: clamp(283px, 70vw, 470px);
}

.news-list-home .mod_newslist {
  margin-bottom: 0;
}
.news-list-home__link {
  margin-top: var(--content-element-margin);
  text-align: center;
}

.mod_newscategories {
  margin-bottom: 70px;
}
.mod_newscategories ul li.reset {
  flex-basis: 100%;
  order: 1;
  text-align: center;
}
.mod_newscategories ul li.reset.active {
  display: none;
}
.mod_newscategories ul li.reset a {
  --underline-size: 1px;
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  background-position: 0 1.2em;
  background-size: 100% 1px;
  transition: background-size 0.8s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
}
.mod_newscategories ul li.reset a:hover {
  animation: link-underline-visible-animate 0.4s;
}
@media (min-width: 768px) {
  .mod_article:first-child .mod_newscategories:first-child {
    margin-top: calc(-1.5rem - 3vw);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .mod_article:first-child .mod_newscategories:first-child {
    margin-top: -3.75rem;
  }
}
@media (min-width: 992px) {
  .mod_newscategories {
    padding-right: var(--column-one);
    padding-left: var(--column-one);
  }
}

.layout-full__intro {
  margin-bottom: var(--content-element-margin-lg);
}
.layout-full__intro h1, .layout-full__intro .h1 {
  margin-bottom: 0.9em;
  font-size: 36px;
}
@media (min-width: 768px) {
  .layout-full__intro h1, .layout-full__intro .h1 {
    font-size: calc(1.425rem + 2.1vw);
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .layout-full__intro h1, .layout-full__intro .h1 {
    font-size: 3rem;
  }
}
.layout-full__intro h2, .layout-full__intro .h2 {
  font-size: calc(1.3125rem + 0.75vw);
  color: #8a8e8d;
}
@media (min-width: 1200px) {
  .layout-full__intro h2, .layout-full__intro .h2 {
    font-size: 1.875rem;
  }
}
.layout-full__intro-category {
  --underline-size: 1px;
  text-decoration: none;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%) 100% 1.2em/0 1px no-repeat;
  background-position: 0 1.2em;
  background-size: 100% 1px;
  transition: background-size 0.8s cubic-bezier(0.19, 1, 0.22, 1), color 0.4s;
  letter-spacing: 0;
  text-transform: uppercase;
}
.layout-full__intro-category:hover {
  animation: link-underline-visible-animate 0.4s;
}
.layout-full__teaser {
  max-width: 39em;
  font-size: 1.25rem;
}
.layout-full__visual {
  position: relative;
  position: relative;
  z-index: 1;
  margin-top: calc(var(--article-padding-inside-y) * -1);
  margin-bottom: var(--content-element-margin-lg);
}
.layout-full__visual::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  z-index: -2;
  width: calc(100vw - var(--scrollbar-width));
  background-color: rgb(235, 240, 245);
  transform: translateX(-50%);
  content: "";
}
.layout-full__visual::after {
  bottom: 60%;
}
@media (min-width: 992px) {
  .layout-full__visual {
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
  }
}

.mod_article .mod_newsreader:last-child {
  margin-bottom: calc(var(--container-margin-bottom) * -1);
}
.mod_article .mod_newsreader:last-child p.back {
  margin-top: calc(var(--article-padding-inside-y) * -1);
  margin-bottom: 0;
  padding: 1em;
  padding-left: var(--section-padding-inside-x);
  background-color: #fff;
}

.mod_newslist .info,
.mod_newsreader .info,
.event .info {
  font-size: 0.875rem;
  margin-bottom: 0.25em;
  color: #8a8e8d;
}

.listing-card__visual {
  overflow: hidden;
}
.listing-card__visual figure {
  position: relative;
  margin-bottom: 0;
}
.listing-card__visual figure .caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5em;
  background-color: rgba(235, 240, 245, 0.8);
}
.listing-card__visual img {
  transition: transform 0.4s;
}
@media (prefers-reduced-motion: reduce) {
  .listing-card__visual img {
    transition: none;
  }
}
.listing-card__visual:hover img {
  transform: scale(1.05);
}
.listing-card__title {
  font-size: 1.25rem;
  margin-bottom: 1em;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.02em;
}
.listing-card__title a {
  color: inherit;
  text-decoration: none;
}
.listing-card__title a:focus, .listing-card__title a:visited {
  color: inherit;
}
.listing-card__title a:hover {
  color: #2572bd;
}
.listing-card__title a:hover, .listing-card__title a:focus, .listing-card__title a:active {
  text-decoration: none;
}
.listing-card__text {
  line-height: 1.5;
}
.listing-card__text:last-child > *:last-child {
  margin-bottom: 0;
}
.listing-card__category {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.listing-card--type-1__visual {
  margin-bottom: 1.875rem;
}

.listing-card--type-2__visual {
  background-color: #f8f9fa;
  aspect-ratio: 370/232;
}
.listing-card--type-2__content {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: calc(1.3125rem + 0.75vw);
  padding-bottom: 1.25rem;
  background-color: #fff;
}
@media (min-width: 1200px) {
  .listing-card--type-2__content {
    padding-top: 1.875rem;
  }
}
.masonry-layout {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  --bs-gutter-y: var(--bs-gutter-x);
}
.masonry-layout > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 576px) {
  .masonry-layout > * {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 992px) {
  .masonry-layout > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

.listing-teaser + .listing-teaser {
  margin-top: 0;
}
.listing-teaser__time {
  font-size: 0.875rem;
  color: #8a8e8d;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.listing-teaser__title {
  font-size: 1.25rem;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.02em;
}
.listing-teaser__location {
  margin-bottom: 0.5em;
}
.listing-teaser .content-text {
  margin-bottom: 0;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .listing-teaser__intro {
    display: flex;
    align-items: center;
  }
  .listing-teaser__content {
    margin-left: 8.5rem;
  }
  .listing-teaser__time {
    flex-shrink: 0;
    width: 8.5rem;
    padding-right: 0.5rem;
  }
  .listing-teaser__time-text {
    display: inline-block;
    max-width: 8em;
  }
}

/* Pages */
/* Homepage */