@use 'sass:map';

/* Miscellaneous */
:root {
    --scrollbar-width: #{'0px'};
    --header-height: #{'0px'};
    --container-padding-x-half: calc(var(--container-padding-x) / 2);
    --content-element-margin-lg: calc(var(--content-element-margin) * 1.8);
    --content-element-margin-xl: 90px;
    --container-padding-x-sm: 10px;
    --section-padding-inside-x-fill: 0px;
    --section-padding-inside-x-filled: calc(var(--section-padding-inside-x) + var(--section-padding-inside-x-fill));
    --content-width: min(calc(100vw - var(--container-padding-x)), #{map.get($container-max-widths, xl)});
    --column-one: calc(var(--content-width) / 12);
    --container-width: min(
        calc(#{map.get($container-max-widths, xl)} - var(--container-padding-x) + var(--scrollbar-width)),
        calc(100vw - var(--container-padding-x) + var(--scrollbar-width))
    );
    --container-complete: calc(((100vw - var(--container-width)) * 0.5) * -1);
    --header-container-complete-right: 0px;
    @include rwd-property('--container-padding-x', container-padding-x);
    @include rwd-property('--container-margin-top', container-margin-top);
    @include rwd-property('--container-margin-bottom', container-margin-bottom);
    @include rwd-property('--article-margin', article-margin);
    @include rwd-property('--article-margin-lg', article-margin-lg);
    @include rwd-property('--article-padding-inside-y', article-padding-inside-y);
    @include rwd-property('--section-padding-inside-x', section-padding-inside-x);
    @include rwd-property('--content-element-margin', content-element-margin);
    @include rwd-property('--banner-subpage-gap-bottom', banner-subpage-gap-bottom);

    @include media-breakpoint-up(lg) {
        --section-padding-inside-x-fill: calc(var(--column-one) * 2);
    }

    @include media-breakpoint-between($header-breakpoint, xxl) {
        --header-container-complete-right: calc(var(--container-padding-x-sm) + var(--container-padding-x) * -0.5);
    }
}

html,
body {
    overflow-x: hidden;
}

.container,
.layout-container,
.layout-header__inside,
.header-container,
.header-navigation__inside,
.layout-footer__inside {
    @extend %responsive-container-xl;
    @include make-container(var(--container-padding-x));
}

.layout-container {
    margin-top: var(--container-margin-top);
    margin-bottom: var(--container-margin-bottom);
}

body {
    letter-spacing: $letter-spacing;
}
