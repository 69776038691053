.section-text-image {
    $this: &;

    + .section-text-image {
        margin-top: var(--article-margin);
    }

    &__visual {
        margin-bottom: var(--content-element-margin);

        img {
            @include add-square-cover;
        }
    }

    &__text {
        @include font-size(14px);

        > *:last-child {
            margin-bottom: 0;
        }

        &:not(:last-child) {
            @include margin-bottom(30px);
        }
    }

    &__link {
        .content-download {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(sm) {
        @include make-row;
        justify-content: space-between;

        > * {
            @include make-col-ready;
        }

        &__visual {
            @include make-col(5);
            margin-bottom: 0;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {
            @include make-col(6);
            @include padding-top(48px);
            @include padding-bottom(48px);
        }

        &__text {
            max-width: 27.5em;
            line-height: calc(30 / 14);
        }

        &--right {
            flex-direction: row-reverse;

            #{$this}__content {
                &-inside {
                    padding-left: var(--column-one);
                }
            }
        }

        &--center {
            #{$this}__content {
                align-self: center;
            }
        }
    }
}
