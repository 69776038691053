// Image hover
%image-hover {
    @include icon($image-hover-icon, after);
    position: relative;
    display: inline-block;
    max-width: 100%;
    text-decoration: none;
    vertical-align: top;

    img {
        display: block;
    }

    &::before {
        @include transition(opacity $transition-time);
        position: absolute;
        inset: 0;
        z-index: 1;
        background-color: rgba($primary, 1);
        opacity: 0;
        content: '';
    }

    &::after {
        @include transition(transform $transition-time, opacity $transition-time);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        color: $image-hover-icon-color;
        font-size: $image-hover-icon-font-size;
        transform: translate(-50%, 50%);
        opacity: 0;
    }

    &:hover {
        &::before {
            opacity: 0.8;
        }

        &::after {
            transform: translate(-50%, -50%);
            opacity: 1;
        }
    }
}

.content-gallery,
.content-image,
.content-text {
    figure {
        @include clearfix;

        a[data-lightbox] {
            @extend %image-hover;
        }
    }
}

.caption {
    margin-top: 1em;
    font-size: 0.85em;
    font-style: italic;
    line-height: 1.2;
}
