@import '~@fontsource/open-sans/scss/mixins';

$fontName: 'Open Sans';
@include fontFace($weight: 300);
@include fontFace($weight: 400);
@include fontFace($weight: 400, $style: italic);
@include fontFace($weight: 600);
@include fontFace($weight: 600, $style: italic);
@include fontFace($weight: 700);
@include fontFace($weight: 800);
