/* Footer */
$footer-breakpoint: md;

@include sticky-footer;

.layout-footer {
    @include padding-bottom(30px);
    @include add-section-bg-100vw($primary);
    position: relative;
    padding-top: 75px;
    color: $white;
    line-height: 24px;

    a {
        @include link-variant($white, $white);
    }

    &::after {
        background-image: url('../../images/bg-pattern.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    @include media-breakpoint-up($footer-breakpoint) {
        @include padding-top(58px);
    }
}

// Footer content (- Top of Footer)
.footer-content {
    @include margin-bottom(40px);
    @include padding-bottom(40px);
    display: grid;
    grid-template: 1fr 1fr / auto 1fr;
    grid-template-areas:
        'col-1 col-1'
        'col-2 col-2'
        'col-3 col-3';
    gap: calc(#{$grid-gutter-width} / 2);
    border-bottom: 1px solid $border-color;

    .footer-headline {
        grid-area: col-1;
    }

    .footer-contact {
        grid-area: col-2;
    }

    .footer-newsletter {
        grid-area: col-3;
    }

    @include media-breakpoint-up($footer-breakpoint) {
        grid-template-areas:
            'col-1 col-1 col-2'
            'col-3 col-3 col-2';
    }
}

.footer-headline {
    @extend %text-hyphens;
    max-width: 18em;
    margin-bottom: 1.5em;
    font-size: rem(30px, $font-size-mobile);
    hyphenate-limit-chars: 10 4 3;

    @include media-breakpoint-down($footer-breakpoint) {
        line-height: calc(40 / 30);
    }

    @include media-breakpoint-up($footer-breakpoint) {
        @include font-size(40px);
        margin-bottom: 0.9em;
    }
}

.footer-logo {
    margin-bottom: $vr;
}

.footer-address {
    margin-bottom: $vr;
}

.footer-newsletter {
    &__headline {
        @include font-size(14px);
        margin-bottom: 1.2em;
    }
}

.footer-contact {
    @include font-size(14px);

    &__links {
        margin-bottom: $vr;
    }

    &__phone a {
        @include link-underline;
    }

    &__email a {
        @include link-underline-visible-hover;
    }

    @include media-breakpoint-up($footer-breakpoint) {
        @include padding-top(17px);
    }

    @include media-breakpoint-up(lg) {
        // @todo
        @include padding-right(80px);
    }
}

// - Bottom of Footer

// Footer navigation
.footer-navigation {
    @include margin-bottom(80px);

    ul {
        @include list-unstyled;
        font-weight: bold;

        a {
            @include link-variant(inherit, $secondary);
        }

        strong {
            color: $secondary;
            font-weight: inherit;
        }

        @include media-breakpoint-down($footer-breakpoint) {
            li {
                margin-bottom: 0.8em;
            }
        }

        @include media-breakpoint-up($footer-breakpoint) {
            @include make-row-col(rem(45px));
        }
    }
}

// Footer terms
.footer-terms {
    @include font-size(12px);
    text-align: center;

    @include media-breakpoint-up($footer-breakpoint) {
        @include make-row-col(40px);
        justify-content: center;
    }
}

.footer-terms__menu {
    ul {
        @include list-unstyled;
        @include make-row-col(40px);
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0;

        li {
            position: relative;

            a {
                @include link-underline-visible-hover;
            }

            strong {
                color: $text-muted;
            }

            &::after {
                position: absolute;
                top: 0.5em;
                right: 0;
                bottom: 0.5em;
                width: 1px;
                background-color: $white;
                content: '';
            }

            @include media-breakpoint-down($footer-breakpoint) {
                &:last-child::after {
                    content: none;
                }
            }
        }
    }
}
