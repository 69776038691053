@import 'variables';

@font-face {
    font-weight: normal;
    font-family: '#{$icomoon-font-family}';
    font-style: normal;
    src:
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff');
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    font-weight: normal;

    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    speak: never;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-close {
    &::before {
        content: $icon-close;
    }
}

.icon-chevrons-right {
    &::before {
        content: $icon-chevrons-right;
    }
}

.icon-chevrons-left {
    &::before {
        content: $icon-chevrons-left;
    }
}

.icon-chevron-right {
    &::before {
        content: $icon-chevron-right;
    }
}

.icon-chevron-left {
    &::before {
        content: $icon-chevron-left;
    }
}

.icon-chevron-down {
    &::before {
        content: $icon-chevron-down;
    }
}

.icon-chevron-up {
    &::before {
        content: $icon-chevron-up;
    }
}

.icon-check-square {
    &::before {
        content: $icon-check-square;
    }
}

.icon-zoom {
    &::before {
        content: $icon-zoom;
    }
}

.icon-hat {
    &::before {
        content: $icon-hat;
    }
}

.icon-arrow-right {
    &::before {
        content: $icon-arrow-right;
    }
}

.icon-arrow-left {
    &::before {
        content: $icon-arrow-left;
    }
}

.icon-search {
    &::before {
        content: $icon-search;
    }
}

.icon-download {
    &::before {
        content: $icon-download;
    }
}
