/* Accordion */
.ce_accordion {
    background-color: $accordion-bg;
    box-shadow: inset 0 calc($accordion-border-width * -1) 0 $accordion-border-color;

    .toggler {
        @include border-radius($accordion-inner-border-radius);
        @include font-size(24px);
        @include transition($accordion-transition);
        position: relative;
        display: flex;
        gap: 0.5em;
        width: 100%;
        padding: $accordion-button-padding-y $accordion-button-padding-x $accordion-button-padding-y 0;
        color: $accordion-button-color;
        font-weight: $font-weight-extra-bold;
        line-height: calc(24 / 20);
        background-color: $accordion-button-bg;

        &::after {
            @include transition($accordion-icon-transition);
            position: relative;
            top: 0.2em;
            flex-shrink: 0;
            width: $accordion-icon-width;
            height: $accordion-icon-width;
            margin-left: auto;
            background-image: escape-svg($accordion-button-icon);
            background-repeat: no-repeat;
            background-size: $accordion-icon-width;
            content: '';
        }
    }

    .accordion {
        @include font-size(14px);
        height: 0;
        overflow: hidden;
        line-height: calc(30 / 14);
        transition-timing-function: ease;
        transition-property: height, visibility;

        > div {
            padding: $accordion-body-padding-y $accordion-body-padding-x 3.3rem 0;
            color: $accordion-color;
            background-color: $accordion-bg;

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.active .toggler {
        color: $accordion-button-active-color;
        background-color: $accordion-button-active-bg;

        &::after {
            background-image: escape-svg($accordion-button-active-icon);
            transform: $accordion-icon-transform;
        }
    }

    + .ce_accordion {
        margin-top: calc(var(--content-element-margin) * -1 + #{$accordion-element-gap});
    }

    &--listing {
        @include padding-top(10px);
        @include padding-bottom(10px);
        box-shadow: inset 0 calc($accordion-border-width * -1) 0 $border-color;

        &:first-child {
            border-top: 1px solid $border-color;
        }

        .toggler {
            @include padding-top(20px);
            @include padding-bottom(20px);
        }

        .accordion {
            font-size: 1rem;

            > div {
                padding: 0 2rem 1.2rem 0;
            }
        }
    }
}
