:root {
    --carousel-button-width: #{rem(60px)};
    --swiper-theme-color: #{$primary};
}

.swiper-equal {
    .swiper-slide {
        height: auto;
    }
}

.swiper-equal {
    height: auto;
}

.swiper:not(.swiper-initialized) {
    .carousel-button {
        display: none;
    }
}

// Buttons wrapper
.carousel-intro {
    display: flex;
    gap: rem(20px);
    margin-bottom: var(--content-element-margin);

    > * {
        margin-bottom: 0;
    }

    .headline-group {
        flex-grow: 1;
    }
}

.carousel-button-wrapper {
    z-index: 1;
    display: flex;

    .mod_newslist & {
        @include margin-top(60px);

        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 1rem;
        }
    }
}

.carousel-button-center {
    justify-content: center;
}

// Single button
.carousel-button {
    @include border-radius(var(--carousel-button-width));
    @include transition(background-size $transition-time, color $transition-time);
    @include link-variant($black, $white);
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--carousel-button-width);
    height: calc(var(--carousel-button-width) * 0.565);
    font-size: 0.75em;
    background-repeat: no-repeat;
    background-size: 0 100%;
    border: 1px solid #dcdcdf;
    cursor: pointer;

    &-prev {
        @include gradient-x($primary, $primary);
        margin-right: 0.5rem;
        background-position: right;

        @include media-breakpoint-up(md) {
            @include margin-right(20px);
        }
    }

    &-next {
        @include gradient-x($primary, $primary);
    }

    &:hover {
        background-size: 100% 100%;
    }

    &.swiper-button-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

// Progressbar
.swiper-pagination-progressbar.swiper-pagination-horizontal {
    @include margin-top(26px);
    position: relative;
    bottom: 0;
    height: 3px;
    background-color: $border-color;
    box-shadow:
        inset 0 1px 0 $body-bg,
        inset 0 -1px 0 $body-bg;
}

// Carousel to the right edge and with the mask
.carousel-right-mask {
    position: relative;

    &::before {
        @include gradient-x(rgba($body-bg, 0.2), rgba($body-bg, 1));
        position: absolute;
        top: 0;
        right: var(--container-complete);
        bottom: 0;
        z-index: 2;
        background-color: rgba($body-bg, 0.7);
        content: '';

        @include media-breakpoint-up($header-breakpoint) {
            right: max(var(--container-complete), -120px);
            left: calc(100% - var(--header-container-complete-right));
        }
    }

    .carousel-right-mask__inside {
        margin-right: max(var(--container-complete), -120px);
        overflow: hidden;
    }

    .carousel-right-mask__content {
        max-width: var(--container-width);
    }

    .swiper {
        overflow: visible;
    }
}

.swiper-pagination-buttons {
    @extend %list-buttons-group;
    margin-top: var(--content-element-margin);

    .swiper-pagination-bullet {
        width: auto;
        height: auto;
        margin: 0 !important;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        --bs-btn-color: #fff;
        --bs-btn-bg: #8a8e8d;
        --bs-btn-border-color: #8a8e8d;
        pointer-events: none;
    }
}
