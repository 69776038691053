.mod_newslist,
.mod_newsreader,
.event {
    .info {
        @include font-size(14px);
        margin-bottom: 0.25em;
        color: $text-muted;
    }
}

// Common listing styles
.listing-card {
    &__visual {
        overflow: hidden;

        figure {
            position: relative;
            margin-bottom: 0;

            .caption {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                padding: 0.5em;
                background-color: rgba($body-bg, 0.8);
            }
        }

        img {
            @include transition(transform $transition-time);
        }

        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }

    &__title {
        @include font-size(20px);
        margin-bottom: 1em;
        font-weight: $font-weight-bold;
        line-height: calc(24 / 20);
        letter-spacing: $letter-spacing;

        a {
            @include link-variant(inherit, $primary);
        }
    }

    &__text {
        line-height: calc(24 / 16);

        &:last-child {
            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__category {
        @include font-size(12px);
        text-transform: uppercase;
    }
}

.listing-card--type-1 {
    &__visual {
        margin-bottom: $vr-lg;
    }
}

.listing-card--type-2 {
    &__visual {
        background-color: $light;
        aspect-ratio: 370 / 232;
    }

    &__content {
        @include padding-left(20px);
        @include padding-right(20px);
        @include padding-top(30px);
        @include padding-bottom(20px);
        background-color: $white;
    }

    &__text {
        a {
            @extend %text-hyphens;
        }
    }
}

// News list : Masonry layout
.masonry-layout {
    @include make-row($grid-gutter-width);
    @include add-gutter-y;

    > * {
        @include make-col-ready;

        @include media-breakpoint-up(sm) {
            @include make-col(6);
        }

        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
    }
}

// Event list
.listing-teaser {
    + .listing-teaser {
        margin-top: 0;
    }

    &__time {
        @include font-size(14px);
        color: $text-muted;
        font-weight: $font-weight-bold;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    &__title {
        @include font-size(20px);
        margin-bottom: 0;
        font-weight: $font-weight-bold;
        line-height: calc(24 / 20);
        letter-spacing: $letter-spacing;
    }

    &__location {
        margin-bottom: 0.5em;
    }

    .content-text {
        margin-bottom: 0;
        line-height: calc(24 / 16);
    }

    @include media-breakpoint-up(md) {
        &__intro {
            display: flex;
            align-items: center;
        }

        &__content {
            margin-left: 8.5rem;
        }

        &__time {
            flex-shrink: 0;
            width: 8.5rem;
            padding-right: 0.5rem;

            &-text {
                display: inline-block;
                max-width: 8em;
            }
        }
    }
}
