@use 'sass:math';
@use 'sass:color';

/* IcoMoon fonts */
$icomoon-font-path: '../icons/fonts';

// Components
$transition-time: 0.4s;
$letter-spacing: 0.02em;
$aspect-ratios: (
    '1x1': 100%,
    '4x3': calc(3 / 4 * 100%),
    '43': calc(3 / 4 * 100%),
    '3x2': calc(2 / 3 * 100%),
    '32': calc(2 / 3 * 100%),
    '16x9': calc(9 / 16 * 100%),
    '169': calc(9 / 16 * 100%),
    '16x10': calc(10 / 16 * 100%),
    '1610': calc(10 / 16 * 100%),
    '21x9': calc(9 / 21 * 100%),
    '219': calc(9 / 21 * 100%),
);

// Spacing
$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 2,
    6: $spacer * 2.5,
    7: $spacer * 3,
    8: $spacer * 3.5,
    9: $spacer * 4,
);

// Color system
$primary: #2572bd;
$primary-dark: color.adjust($primary, $lightness: -15%);
$secondary: #bda013;
$text-muted: #8a8e8d;
$bg-dark: #012e4b;
$border-color: #bbc0c2;

// Typography
$font-size-base: 1rem;
$input-btn-font-size-lg: 1.15rem;
$font-size-desktop: 16px;
$font-size-tablet: 15px;
$font-size-mobile: 15px;
$line-height-base: math.div(30, 16);
$font-family-base: 'Open Sans', sans-serif;
$font-weight-light: 300;
$font-weight-light: 300;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

// Headings
$h1-font-size: rem(80px);
$h2-font-size: rem(36px);
$h3-font-size: rem(24px);
$h4-font-size: rem(20px);
$h5-font-size: rem(18px);
$h6-font-size: rem(16px);
$headings-margin-bottom: 0.75em;
$headings-line-height: 1.5;
$headings-letter-spacing: 0;
$headings-font-weight: $font-weight-extra-bold;

// Links
$link-decoration: none;

// RFS
$rfs-breakpoint: 1200px;
$rfs-base-value: 1.25rem;
$rfs-rem-value: strip-units($font-size-desktop);

// Body
$body-color: #111315;
$body-bg: rgb(235 240 245);

/* Vertical rhythm */
$vr: $line-height-base * 0.65rem;
$vr-lg: $line-height-base * 1rem;
$vr-xl: 3rem;

// Container padding
$container-padding-x: var(--container-padding-x);
$grid-gutter-width: 30px;

// Grid containers
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1208px,
);

// Responsive values
$custom-responsive-values: (
    container-padding-x: (
        xs: 40px,
    ),
    container-margin-top: (
        xs: 90px,
        md: 6rem,
        xl: 9.4rem,
    ),
    container-margin-bottom: (
        xs: 3rem,
        md: 5rem,
        xl: 6.5rem,
    ),
    article-margin: (
        xs: 90px,
        md: 5rem,
        lg: 7.75rem,
    ),
    article-margin-lg: (
        xs: 110px,
    ),
    article-padding-inside-y: (
        xs: 3.5rem,
        md: 5rem,
        lg: 7.75rem,
    ),
    section-padding-inside-x: (
        xs: var(--column-one),
    ),
    content-element-margin: (
        xs: 2.5rem,
        md: 3rem,
    ),
    banner-subpage-gap-bottom: (
        xs: 4.5rem,
        md: 7rem,
        lg: 8rem,
        xxl: 11.25rem,
    ),
);

/* Mobile menu */
$mobile-menu-breakpoint: null;
$mobile-menu-light-bg: $body-bg;
$mobile-menu-light-color: $body-color;
$mobile-menu-dark-bg: #333;
$mobile-menu-dark-color: #ddd;
$mobile-menu-font-size: $font-size-base;
$mobile-menu-trigger-bg: transparent;
$mobile-menu-trigger-color: #fff;
$mobile-menu-trigger-gap: 4px;
$mobile-menu-trigger-height: 2px;
$mobile-menu-trigger-padding: 0;
$mobile-menu-trigger-space: 3px;
$mobile-menu-trigger-transition: transform $transition-time ease-in-out;
$mobile-menu-trigger-width: 18px;

/* Desktop dropdown navigation */
$dropdown-menu-bg: $primary;
$dropdown-menu-color: #fff;
$dropdown-menu-font-size: $font-size-base;
$dropdown-menu-shadow: 0 0 8px 1px rgb(0 0 0 / 10%);
$dropdown-menu-width: rem(250px);
$dropdown-menu-item-border-color: rgb(255 255 255 / 30%);
$dropdown-menu-item-border-width: 1px;
$dropdown-menu-item-padding-x: 1rem;
$dropdown-menu-item-padding-y: 1rem;
$dropdown-menu-item-hover-bg: rgb(0 0 0 / 10%);
$dropdown-menu-item-hover-color: inherit;
$dropdown-menu-item-active-bg: rgb(0 0 0 / 20%);
$dropdown-menu-item-active-color: #fff;

// Element : Download
$download-link-color: $primary;
$download-link-hover-color: $primary-dark;
$download-link-decoration: none;
$download-link-hover-decoration: $download-link-decoration;
$download-icon: $icon-download;

// Image Hover
$image-hover-icon-background-color: $primary;
$image-hover-icon-border-radius: 0;
$image-hover-icon-color: #fff;
$image-hover-icon-font-size: 3.1em;
$image-hover-icon-padding: 0.8rem;
$image-hover-icon: $icon-zoom;

// Accordion
$accordion-element-gap: $vr-lg;
$accordion-padding-y: 0.45rem;
$accordion-padding-x: 0.5rem;
$accordion-bg: transparent;
$accordion-button-bg: transparent;
$accordion-button-active-bg: $accordion-button-bg;
$accordion-button-color: $body-color;
$accordion-button-active-color: $accordion-button-color;
$accordion-icon-color: $primary;
$accordion-icon-active-color: $accordion-icon-color;
$accordion-border-color: $primary;

// Table
$table-cell-padding-y: 0.45rem;
$table-cell-padding-x: 0;

// Buttons + Forms
$input-btn-padding-y-lg: 0.25rem;
$input-btn-padding-x-lg: 1.5rem;

// Buttons
$btn-padding-y: 0.615rem;
$btn-padding-x: 1.175rem;
$btn-font-size: rem(14px);
$btn-border-radius: 1.5rem;

// Forms
$widget-margin-bottom: 2.2rem;
$input-border-radius: 0;
$input-border-color: #000;
$input-box-shadow: 0;
$input-focus-box-shadow: 0;
$form-floating-height: 2.2rem;
$form-floating-input-padding-t: 1.2rem;
$form-floating-input-padding-b: 0.4rem;
$form-floating-padding-y: 0.4rem;
$form-floating-input-padding-b: 0.4rem;
$form-floating-label-opacity: 1;
$form-floating-label-transform: scale(0.7) translateY(-1.75rem) translateX(0);
$form-check-input-border-radius: 0;
$form-check-input-border: 1px solid $input-border-color;

// Scrollbar
$scrollbar-size: 8px;
$scrollbar-border-color: $primary-dark;
$scrollbar-border-width: 1px;
$scrollbar-bg: $primary-dark;
$scrollbar-thumb-bg: #000;

// Alert
$alert-border-radius: 0;

// Header
$header-breakpoint: md;
