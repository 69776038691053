.box-intro {
    &__visual {
        &-inside {
            position: relative;

            img {
                @include add-square-cover;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &__headline {
            @include margin-bottom(8px);
        }

        &__content {
            @include margin-bottom(5rem);
        }

        .event-list-home & {
            max-width: 21em;

            &__visual {
                margin-left: max(var(--container-complete), -130px);
                text-align: right;
                border-left: 10px solid $body-bg;

                img {
                    width: 100%;
                }
            }

            &__content-text {
                max-width: 18em;
            }
        }
    }
}
