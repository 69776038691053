.section-intro {
    $this: &;
    margin-bottom: var(--content-element-margin-xl);

    &__visual {
        margin-bottom: var(--content-element-margin-xl);

        img {
            @include add-square-cover(clamp(rem(70px), 10vw, rem(120px)));
            width: 100%;
        }
    }

    &__headline {
        margin-bottom: 0.75em;
    }

    &__text {
        > *:last-child {
            margin-bottom: 0;
        }

        a {
            @include link-underline-visible-hover;
        }
    }

    .mod_article:first-child &.has-banner-type-2:first-child {
        .section-intro__visual {
            margin-top: calc((var(--container-margin-top) + var(--banner-subpage-gap-bottom)) * -1);
        }
    }

    body:has(.banner-subpage--type-2) .mod_article:first-child &:first-child {
        .section-intro__visual {
            margin-top: calc((var(--container-margin-top) + var(--banner-subpage-gap-bottom)) * -1);
        }
    }

    @include media-breakpoint-up(md) {
        @include make-row;
        flex-direction: row-reverse;

        &__visual,
        &__content {
            @include make-col-ready;
            @include make-col(6);
        }

        &__visual {
            margin-bottom: 0;

            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__visual {
            @include make-col(7);
            align-self: flex-start;
        }

        &__content {
            @include make-col(5);
        }
    }
}
