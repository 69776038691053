h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    letter-spacing: 0;
}

h1,
.h1 {
    @extend %text-hyphens;
    hyphenate-limit-chars: 10 4 3;

    @include media-breakpoint-down(md) {
        font-size: 50px;
    }
}

h2,
.h2 {
    @include media-breakpoint-down(md) {
        font-size: 27px;

        .section--bg-white & {
            font-size: 36px;
        }
    }
}

.h2-sm,
.content-gallery > h2 {
    @include media-breakpoint-down(md) {
        font-size: 21px;
    }
}

h3,
.h3 {
    @include media-breakpoint-down(md) {
        .section--bg-white &,
        &.job-item__category {
            font-size: 24px;
        }
    }
}

// Headline with line next to it
.headline-group {
    display: flex;
    gap: rem(20px);
    align-items: center;
    font-weight: $font-weight-bold;
    font-size: 1rem;
    letter-spacing: $letter-spacing;

    &::after {
        flex-grow: 1;
        height: 1px;
        background-color: $black;
        content: '';
    }
}

// Headline : Extended
.headline-section {
    &__main {
        margin-bottom: 0;
        font-size: 27px;
    }

    &__subheadline {
        @include font-size(30px);
        color: $text-muted;
        font-weight: $font-weight-semi-bold;
        line-height: 1;
        letter-spacing: 0;
    }

    @include media-breakpoint-up(md) {
        @include font-size(36px);
    }
}

// Headline : Intro
.headline-intro {
    margin-bottom: var(--content-element-margin);

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__main {
            margin-bottom: 0.2em;
        }

        &__action {
            margin-left: $grid-gutter-width;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-right: var(--section-padding-inside-x);
        margin-left: var(--section-padding-inside-x);
    }
}

.headline-light-primary {
    margin-bottom: 1.5em;
    color: $primary;
    font-weight: $font-weight-normal;
    letter-spacing: $letter-spacing;

    .accordion &:first-child {
        margin-top: $accordion-padding-y * -2;
    }
}
