@use 'sass:meta';

/* Download */
.content-downloads {
    ul {
        @include list-unstyled;
    }
}

.content-download + .content-download {
    .download-element:first-child {
        margin-top: calc(var(--content-element-margin) * -0.4);
    }
}

.download-element {
    margin-bottom: calc(var(--content-element-margin) * 0.4);

    a {
        @include link-variant(
            $download-link-color,
            $download-link-hover-color,
            $download-link-decoration,
            $download-link-hover-decoration
        );
        display: flex;
        width: fit-content;
        font-weight: $font-weight-bold;
        font-size: 12px;
        text-transform: uppercase;

        @if meta.variable-exists(download-icon) {
            @include icon($download-icon);

            &::before {
                position: relative;
                top: -0.1em;
                margin-right: 0.6em;
                font-weight: $font-weight-extra-bold;
                font-size: 1.1em;
                line-height: inherit;
            }
        }
    }

    .size {
        position: relative;
        top: 0.2em * $line-height-base;
        padding-left: 0.8em;
        font-size: 0.8em;
        white-space: nowrap;
        opacity: 0.8;
    }
}

.enclosure {
    @include list-unstyled;
}
