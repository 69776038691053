/* Header */
.header-container {
    @include font-size(12px);
    position: relative;
    z-index: 9;
    display: flex;
    justify-content: space-between;

    body.menu-active & {
        color: $white;
    }
}

.header-content {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xxl) {
        margin-right: var(--header-container-complete-right);
    }

    @include media-breakpoint-down($header-breakpoint) {
        margin-right: calc(var(--container-padding-x) * -0.5);
    }
}

.header-logo {
    @include transition(padding-top $transition-time);
    align-self: center;
    max-width: 60%;
    margin-right: 2rem;

    &__menu-open {
        display: none;
    }

    body.menu-active & {
        &__menu-open {
            display: block;
        }

        &__menu-closed {
            display: none;
        }
    }

    @include media-breakpoint-up($header-breakpoint) {
        @include padding-top(16px);

        .sticky & {
            padding-top: 0;
        }
    }
}

.header-links {
    display: none;

    ul {
        @extend %reset;
    }

    @include media-breakpoint-up($header-breakpoint) {
        @include margin-right(27px);
        display: flex;
        align-items: center;

        ul {
            display: flex;
            gap: 3.2em;
        }

        a {
            @include link-variant;
            @include link-underline;
        }
    }
}

.header-search {
    $this: &;

    &__button {
        display: none;
    }

    &__form {
        margin-bottom: var(--content-element-margin);

        &-close {
            display: none;
        }

        .formbody {
            position: relative;

            .widget {
                margin: 0;
            }

            input.text {
                padding-right: 3em;
                padding-left: 1.5em;
                color: inherit;
                background-color: transparent;
                border: 1px solid $white;
                border-radius: 2em;

                &::placeholder {
                    color: $white;
                    font-style: italic;
                }
            }

            .widget-submit {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;

                button {
                    min-width: unset;
                    height: 100%;
                    padding: 0 1em;
                    background: transparent;
                    border: 0;
                }
            }
        }
    }

    @include media-breakpoint-up($header-breakpoint) {
        @include margin-right(24px);

        &__button {
            @include transition(background-color $transition-time, color $transition-time);
            @include link-variant(inherit, $white);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.6em;
            height: 1.6em;
            font-size: rem(22px);
            background-color: transparent;
            border-width: 0;
            border-radius: 50%;

            &:hover {
                background-color: $primary;
            }
        }

        &__form {
            @include add-section-bg-100vw($primary);
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            display: none;
            align-items: center;
            padding: 1.5rem;
            color: $white;
            background: $primary;

            &-close {
                display: block;
                margin-left: 1rem;
                font-size: 2em;
                cursor: pointer;

                &:hover {
                    color: $body-bg;
                }
            }

            form {
                flex-grow: 1;
            }
        }

        &.active {
            #{$this}__form {
                display: flex;
            }
        }
    }
}

// sticky header
.header-placeholder {
    + .sticky-header {
        @include transition(background-color $transition-time);
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 99;
        background-color: $body-bg;

        body.menu-active & {
            background-color: transparent !important;
            box-shadow: unset !important;
        }

        &.sticky {
            background-color: $white;
            box-shadow: 0 0 8px rgba($dark, 0.25);
        }
    }
}

.sticky-header--init {
    * {
        transition: none !important;
    }
}
