.news-list-carousel {
    .swiper-slide {
        width: clamp(283px, 70vw, 470px);
    }
}

.news-list-home {
    .mod_newslist {
        margin-bottom: 0;
    }

    &__link {
        margin-top: var(--content-element-margin);
        text-align: center;
    }
}
