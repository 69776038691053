.boxes-download-wrapper {
    @include media-breakpoint-up(lg) {
        .carousel-intro {
            margin-right: var(--column-one);
            margin-left: var(--column-one);
        }

        .carousel-right-mask__inside {
            margin-left: var(--column-one);
        }

        .carousel-right-mask__content {
            padding-right: var(--column-one);
        }
    }
}

.boxes-download {
    .content-download {
        margin-bottom: 0;
    }
}

.box-download {
    $this: &;
    @include transition(background-color $transition-time);
    @include link-variant($white, $white);
    @include font-size(48px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0.2em;
    color: $white;
    font-weight: $font-weight-bold;
    line-height: 1;
    background-color: $bg-dark;
    aspect-ratio: 1;

    &__icon {
        @include icon($icon-download);
        display: none;
        flex-basis: 100%;
        text-align: center;
    }

    &:hover {
        background-color: $primary;

        #{$this}__icon {
            display: block;
        }
    }
}
