.ce_rsce_banner:last-child {
    margin-bottom: 0;
}

.banner {
    &__visual {
        margin-bottom: 2rem;

        &-inside {
            @include add-square-cover;
            position: relative;
            margin-right: var(--container-complete);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include media-breakpoint-up(lg) {
                --size-square-cover: calc(var(--section-padding-inside-x) * 1.2);
            }
        }
    }

    &__content {
        &-subheadline {
            @include font-size(30px);
            margin-bottom: 0.9em;
            margin-left: var(--section-padding-inside-x);
            line-height: $headings-line-height;
            text-transform: uppercase;
        }

        &-headline {
            margin-bottom: 0.6em;
            color: $primary;
            font-weight: $font-weight-extra-bold;
            font-size: clamp(40px, 7.6vw, 110px);
            line-height: calc(90 / 110);
            text-transform: uppercase;
        }

        &-text {
            @extend %text-hyphens;
            @include font-size(20px);
            margin-left: var(--section-padding-inside-x);
            line-height: calc(30 / 20);
            letter-spacing: 0;
            hyphenate-limit-chars: 10 4 4;

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-up(md) {
        @include make-row;
        flex-direction: row-reverse;

        &__visual,
        &__content {
            @include make-col-ready;
        }

        &__visual {
            @include make-col(7);
            align-self: flex-start;
            margin-bottom: 0;

            &-inside {
                height: 100%;
                margin-right: calc(var(--container-padding-x) * -0.5);

                @include media-breakpoint-up($header-breakpoint) {
                    border-right: var(--container-padding-x-sm) solid $body-bg;
                }

                @include media-breakpoint-up(xl) {
                    margin-right: max(var(--container-complete), -120px);
                    border-right: 0;
                }

                @include media-breakpoint-up(xxl) {
                    min-height: 700px;
                }
            }

            .content-gallery,
            figure {
                margin: 0;
            }

            img {
                position: relative;
                z-index: -1;
                max-height: 700px;
            }
        }

        &__content {
            @include make-col(5);
            position: relative;
            z-index: 2;
            align-self: flex-end;
            padding-top: 11%;

            &-subheadline {
                margin-right: -2em;
            }

            &-headline {
                margin-right: -2.15em;
            }
        }
    }
}
