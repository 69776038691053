.job-list {
    &__filters {
        @extend %list-buttons-group;

        @include media-breakpoint-only(md) {
            margin-right: calc(var(--column-one) * -1);
            margin-left: calc(var(--column-one) * -1);
        }
    }

    @include media-breakpoint-down(md) {
        margin-top: 80px;
    }

    @include media-breakpoint-up(md) {
        margin-right: var(--column-one);
        margin-left: var(--column-one);
    }
}

.job-item {
    height: 100%;

    &__visual {
        margin-bottom: var(--content-element-margin);

        img {
            @include add-square-cover;
            width: 100%;
        }
    }

    &__category {
        margin-bottom: 1em;
    }

    &__text {
        @include font-size(14px);
        line-height: calc(30 / 14);

        &:not(:last-child) {
            @include margin-bottom(30px);
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        @include make-row;

        > * {
            @include make-col-ready;
            @include make-col(6);
        }

        &__visual {
            align-self: flex-start;
            margin-bottom: 0;
        }

        &__content {
            @include padding-top(50px);
            @include padding-bottom(20px);

            &-inside {
                max-width: 23.5em;
                margin-left: auto;
            }
        }
    }
}
