/* Typography */
html {
    font-size: $font-size-mobile;

    @include media-breakpoint-up(md) {
        font-size: $font-size-tablet;
    }

    @include media-breakpoint-up(lg) {
        font-size: $font-size-desktop;
    }
}
