/* Helper class */

/* Reset style */
%reset {
    margin: 0;
    padding: 0;
    list-style: none;
    background: transparent;
    border: 0;
}

.reset {
    @extend %reset;
}

/* Links */
a {
    @include transition(color $transition-time);

    .content-text & {
        @include link-underline-visible-hover;
    }
}

// Link with arrow
.link-more {
    @include link-variant($primary, $primary-dark);
    @include icon($icon-arrow-right, after, 0.8em);
    @include font-size(12px);
    display: inline-flex;
    align-items: center;
    color: $primary;
    font-weight: $font-weight-bold;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    &::after {
        @include transition(transform $transition-time);
    }

    &:hover {
        &::after {
            transform: translateX(0.25em);
        }
    }
}

// Link back
.back {
    margin-top: $vr;
    padding-top: 1em;
    border-top: 1px solid $border-color;

    a {
        @include transition(background-size $transition-time, color $transition-time);
        @include icon($icon-arrow-left, $margin: 0.5em);
        @include link-variant($body-color, $white);
        @include border-radius;
        @include gradient-x($primary, $primary);
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 0.4em 0.8em;
        font-size: rem(16px);
        background-color: $light;
        background-repeat: no-repeat;
        background-size: 0 100%;

        &:hover {
            background-size: 100% 100%;
        }
    }
}

// Hyperlinks
.content-text + .content-hyperlink {
    margin-top: calc(var(--content-element-margin) * -0.25);
}

.content-hyperlink a {
    @extend .link-more;
}

/* Text */
%text-hyphens {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}

// Margin
.mb-article {
    margin-bottom: var(--article-margin) !important;
}

.mb-content-element {
    margin-bottom: var(--content-element-margin) !important;
}

.mb-content-element-lg {
    margin-bottom: var(--content-element-margin-lg) !important;
}

.mb-container-reset {
    margin-bottom: calc(var(--container-margin-bottom) * -1) !important;
}

// Alert
p.empty {
    @extend .alert;
    @extend .alert-warning;
    text-align: center;
}

// Custom scrollbar
%scrollbar-style {
    overflow: auto;

    &::-webkit-scrollbar {
        width: $scrollbar-size;
        height: $scrollbar-size;
        background-color: $scrollbar-bg;
    }

    &::-webkit-scrollbar-track {
        background-color: $scrollbar-bg;
        box-shadow: inset 0 0 0 $scrollbar-border-width $scrollbar-border-color;
    }

    &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-bg;
        border: solid $scrollbar-border-color;
        border-width: 0 $scrollbar-border-width;
    }
}
