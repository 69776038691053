.ce_rsce_team:last-child {
    margin-bottom: 0;
}

.team {
    @include make-row;
    @include add-gutter-y(3.2rem);

    > * {
        @include make-col-ready;

        @include media-breakpoint-up(md) {
            @include make-col(6);
        }
    }

    @include media-breakpoint-up(md) {
        --#{$variable-prefix}gutter-y: 5.4rem;
    }
}

.person {
    @include make-row(1.2rem);
    flex-wrap: nowrap;

    &__visual,
    &__content {
        @include make-col-ready;
    }

    &__visual {
        @include make-col-auto;

        img {
            border-radius: 50%;
        }
    }

    &__content {
        @include make-col;
        @include padding-top(17px);
        line-height: calc(24 / 16);

        &-intro {
            @include margin-bottom(20px);
            font-weight: $font-weight-bold;

            &__department {
                @include font-size(20px);
                line-height: calc(24 / 20);
            }
        }

        &-name {
            font-weight: $font-weight-bold;

            &:last-child {
                @include margin-bottom(10px);
            }
        }

        .email {
            @include link-underline-visible-hover;
            @include margin-bottom(20px);
            display: inline-block;
        }
    }
}
