.event-list-home {
    @include make-row;
    @include add-gutter-y(1rem);

    .mod_article:last-child & {
        margin-bottom: 0;
    }

    &__intro,
    &__listing {
        @include make-col-ready;
    }

    &__listing {
        &-inside {
            @include padding(40px);
            background-color: $white;

            @include media-breakpoint-down(md) {
                padding-bottom: 75px;
            }
        }
    }

    .mod_eventlist {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        --padding-y: 3.7rem;

        &__intro {
            @include make-col(4);
            padding-top: calc(var(--padding-y) * 0.8);
        }

        &__listing {
            @include make-col;

            &-inside {
                position: relative;
                padding: var(--padding-y) 0;

                &::before {
                    position: absolute;
                    inset: 0 var(--container-complete) 0 calc(var(--section-padding-inside-x) * -1);
                    z-index: -1;
                    background: $white;
                    content: '';
                }
            }
        }
    }
}
