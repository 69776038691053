@use 'sass:color';
@use 'sass:math';

.mod_search {
    .formbody {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: math.div($vr, 2);
    }

    .widget-text {
        flex: 1;
        margin-bottom: 0;
    }

    .widget-submit {
        margin-top: 0;
    }

    .text {
        border-right: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .submit {
        width: rem(150px);
        height: 100%;
        padding: 0;
        line-height: 1;
        border-left: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .widget-radio {
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        margin-top: math.div($vr, 2);
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }
    }

    h3 {
        margin-bottom: 0;

        .relevance {
            color: color.adjust($body-color, $lightness: 15);
            font-weight: 400;
            font-size: 80%;
        }
    }

    p.header {
        margin-bottom: 0;
        padding: math.div($vr, 2) 1rem 0 1rem;
        color: #c5c5c5;
        border-top: 1px solid $border-color;
    }

    p.info {
        padding: 0 1rem math.div($vr, 2) 1rem;
        color: #c5c5c5;
        border-bottom: 1px solid $border-color;
    }

    p.context {
        margin-bottom: 0;

        .highlight {
            background: #ffe680;
        }
    }

    p.url {
        color: color.adjust($body-color, $lightness: 15);
    }

    > div {
        padding-top: $vr;
        border-bottom: 1px solid $gray-200;

        &.pagination {
            border-bottom: 0;
        }
    }

    p.info + div {
        padding-top: 0;
    }
}
