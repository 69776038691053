/* Base */

/* Vertical rhythm */
ul,
ol,
dd,
p,
figure,
pre,
table,
fieldset {
    margin-top: 0;
    margin-bottom: $vr;
}

hr {
    margin-top: $vr;
    margin-bottom: $vr;
}

img[sizes] {
    width: auto;
}

*[class^='ce_'],
*[class^='mod_'],
*[class^='content-'] {
    margin-top: 0;
    margin-bottom: var(--content-element-margin);
}

.mod_article {
    margin-bottom: var(--article-margin);

    &:last-child {
        margin-bottom: 0;
    }
}

.layout-header *[class^='mod_'],
.layout-footer *[class^='mod_'] {
    margin-bottom: 0;
}

/* Grid */
.mod_article .row {
    .ce_column {
        margin-bottom: 0;

        > *[class^='ce_'],
        > *[class^='mod_'],
        > *[class^='content-'] {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &.ce_row {
        margin-bottom: 0;
    }
}

/* Float classes */
.float_left {
    float: left;

    @include media-breakpoint-down(sm) {
        float: none;
    }
}

figure.float_left {
    margin-right: $vr;
    margin-bottom: $vr;

    @include media-breakpoint-down(sm) {
        margin-right: 0;
    }
}

.float_right {
    float: right;

    @include media-breakpoint-down(sm) {
        float: none;
    }
}

figure.float_right {
    margin-bottom: $vr;
    margin-left: $vr;

    @include media-breakpoint-down(sm) {
        margin-left: 0;
    }
}

// Clear the text elements as they may have a floating image inside
.content-text {
    @include clearfix;
}

/* Hide invisible elements */
.invisible {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
}
