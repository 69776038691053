/* Pagination */
.pagination {
    margin-top: var(--content-element-margin);

    ul {
        @extend %reset;
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        justify-content: center;
    }

    li {
        a,
        strong {
            @include rfs(40px, min-width);
            @include rfs(40px, height);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.2em;
            border: 1px solid $border-color;
        }

        strong {
            color: $white;
            font-weight: $font-weight-normal;
            background-color: $primary;
        }

        a {
            @include transition(background-color $transition-time);
            @include link-variant(inherit, $white);

            &:hover {
                background-color: $primary-dark;
            }
        }
    }
}
