/* Reset */
select,
input[type='checkbox'],
input[type='checkbox'] + label,
input[type='radio'],
input[type='radio'] + label {
    cursor: pointer;
}

select[disabled],
input[disabled],
input[type='checkbox'][disabled] + label,
input[type='radio'][disabled] + label {
    cursor: not-allowed;
}

table {
    width: 100%;

    td,
    th {
        padding: 6px;
    }
}

ul ul {
    margin-bottom: 0;
}

figure {
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}
