.mod_newscategories {
    margin-bottom: 70px;

    ul {
        @extend %list-buttons-group;

        li.reset {
            flex-basis: 100%;
            order: 1;
            text-align: center;

            &.active {
                display: none;
            }

            a {
                @include link-underline-visible-hover;
            }
        }

        li:not(.reset) a {
            @extend .btn;
            @extend .btn-outline-primary;
        }
    }

    @include media-breakpoint-up(md) {
        .mod_article:first-child &:first-child {
            @include margin-top(-60px);
        }
    }

    @include media-breakpoint-up(lg) {
        padding-right: var(--column-one);
        padding-left: var(--column-one);
    }
}
