.section--bg-white {
    padding-right: var(--section-padding-inside-x-filled); // Limit content width
    padding-left: var(--section-padding-inside-x);
    background-color: $white;

    &:last-child {
        @extend .mb-container-reset;
    }

    @include media-breakpoint-up(md) {
        [class^='content-'] + [class^='content-'] {
            h2:first-child {
                margin-top: var(--article-margin);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .content-gallery {
            margin-right: calc(var(--section-padding-inside-x) * -1);
        }
    }
}

.section--bg-white-full-width {
    @include add-section-bg-100vw($white);

    &:last-child {
        @extend .mb-container-reset;
    }
}

.section--bg-white,
.section--bg-white-full-width {
    padding-top: var(--article-padding-inside-y);
    padding-bottom: var(--article-padding-inside-y);

    > :last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {
        .mod_article + & {
            padding-top: var(--article-margin);
        }
    }
}

.section-divider-image {
    .section--bg-white-full-width + .mod_article & {
        @include add-section-bg-100vw($white);
        margin-top: calc(var(--article-margin) * -1);

        &::after {
            bottom: 60%;
        }
    }
}
