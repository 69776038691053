.layout-full {
    &__intro {
        margin-bottom: var(--content-element-margin-lg);

        h1 {
            margin-bottom: 0.9em;
            font-size: 36px;

            @include media-breakpoint-up(md) {
                @include font-size(48px);
            }
        }

        h2 {
            @include font-size(30px);
            color: $text-muted;
        }

        &-category {
            @include link-underline-visible-hover;
            letter-spacing: 0;
            text-transform: uppercase;
        }
    }

    &__teaser {
        max-width: 39em;
        font-size: $lead-font-size;
    }

    &__visual {
        @include add-section-bg-100vw($body-bg);
        position: relative;
        z-index: 1;
        margin-top: calc(var(--article-padding-inside-y) * -1);
        margin-bottom: var(--content-element-margin-lg);

        &::after {
            bottom: 60%;
        }

        @include media-breakpoint-up(lg) {
            width: fit-content;
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.mod_article .mod_newsreader:last-child {
    margin-bottom: calc(var(--container-margin-bottom) * -1);

    p.back {
        margin-top: calc(var(--article-padding-inside-y) * -1);
        margin-bottom: 0;
        padding: 1em;
        padding-left: var(--section-padding-inside-x);
        background-color: #fff;
    }
}
