@use 'sass:math';

/* Gallery */
.content-gallery {
    // Clear the default Contao styling
    .content-gallery > ul li.col_first {
        clear: none;
    }

    ul {
        @include make-row;
        @include add-gutter-y;
        @include list-unstyled;
        margin-bottom: 0;
    }

    li {
        @include make-col-ready;
        @include make-col(6, 12);

        figure {
            margin-bottom: 0;
        }
    }

    @for $i from 1 through 12 {
        @include media-breakpoint-up(md) {
            &--cols-#{$i} {
                li {
                    @include make-col(math.div(12, $i), 12);
                }
            }
        }
    }
}
