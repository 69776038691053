.btn-arrow-right {
    @include icon($icon-arrow-right, after);

    &::after {
        margin-left: 1em;
        font-size: 0.9em;
    }
}

.btn-submit {
    min-width: 10em;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    border-radius: 0;
}

// Buttons group
%list-buttons-group {
    --gap: clamp(0.65rem, 2vw, 1rem);
    @include list-unstyled;
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--gap) * 1.3) var(--gap);
    justify-content: center;
}
