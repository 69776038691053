@use 'sass:math';

/* Form */
select,
textarea,
input.text {
    @extend .form-control;

    &.error {
        @extend .is-invalid;
    }
}

select {
    @extend .form-select;
}

.formbody {
    .widget {
        margin-bottom: $widget-margin-bottom;

        &:last-child {
            margin-bottom: 0;
        }

        > label,
        > fieldset > legend {
            display: block;
            margin-bottom: 0.2rem;
            font-weight: bold;

            span.mandatory {
                margin-left: 2px;
                color: $danger;
            }
        }

        > label {
            @include font-size(14px);
            @include margin-bottom(14px);
        }
    }

    p.error {
        margin-bottom: 0.2rem;
        color: $danger;
        font-weight: bold;
    }

    .radio_container,
    .checkbox_container {
        margin: 0;
        padding: 0;
        border: 0;

        > span {
            display: block;
        }
    }

    .captcha {
        width: 75px;
        margin-right: 8px;
        text-align: center;
    }

    .captcha_text {
        font-weight: bold;
    }

    .widget-submit {
        margin-top: var(--content-element-margin);
        text-align: center;
    }

    .submit {
        @extend .btn;
        @extend .btn-lg;
        @extend .btn-primary;
        @extend .btn-submit;
    }
}

/* Ajax form */
.ce_form {
    form {
        transition: opacity $transition-time;

        &[data-ajax-form='loading'] {
            cursor: progress;
            opacity: 0.5;

            .formbody {
                pointer-events: none;
            }
        }
    }

    .form-confirmation {
        @extend .alert;
        @extend .alert-success;

        *:last-child {
            margin-bottom: 0;
        }
    }
}

/* Floating label */

/* autoprefixer: off */
.form-floating {
    select,
    input.text {
        padding-left: 0;
        border-width: 0 0 $input-border-width;
    }

    label {
        color: $text-muted;
        font-style: italic;
    }

    textarea {
        height: auto;
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
    }

    > .form-control,
    > .form-control-plaintext {
        &:focus,
        &:not(:placeholder-shown) {
            padding-top: $form-floating-input-padding-t;
            padding-bottom: $form-floating-input-padding-b;
        }
    }

    > .form-control:focus,
    > .form-control:not(:placeholder-shown),
    > .form-control-plaintext,
    > .form-select {
        ~ label {
            color: $primary;
            font-weight: $font-weight-bold;
            font-style: unset;
            transform: $form-floating-label-transform;
            opacity: 0;
        }
    }

    textarea {
        &:focus,
        &:not(:placeholder-shown) {
            ~ label {
                transform: scale(0.7) translateY(-0.2rem) translateX(0.3em);
            }
        }
    }

    > .form-control:focus {
        ~ label {
            opacity: $form-floating-label-opacity;
        }
    }

    select,
    input.text {
        padding-left: 0;

        ~ label {
            padding-left: 0;
        }
    }

    select.select {
        padding-top: $form-floating-padding-y;

        &:focus {
            box-shadow: none;
        }
    }
}

/* autoprefixer: on */

// Checkbox / Radio
.radio_container,
.checkbox_container {
    > span {
        @extend .form-check;
        clear: both;

        input {
            @extend .form-check-input;
        }

        label {
            @extend .form-check-label;

            a {
                @include link-variant(inherit, $primary);
                @include link-underline-visible-hover;
            }
        }

        input[required] + label {
            &::after {
                position: absolute;
                content: '*';
            }
        }
    }
}

.widget-explanation {
    color: $text-muted;
    font-size: 12px;

    .widget + & {
        margin-top: $widget-margin-bottom * -0.8;
    }
}

.widget + .widget-textarea {
    margin-top: $widget-margin-bottom * 1.4;
}
