/* Page menu and mobile manu */
$header-navigation-breakpoint: lg;

.header-navigation {
    --font-size: clamp(12px, 1vw, 14px);
    --nav-margin: calc(var(--font-size) * 2);
    @include transition(top $transition-time ease-in-out);
    position: fixed;
    top: -100%;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    color: $white;
    font-size: var(--font-size);
    line-height: calc(36 / 30);
    background: url('../../../images/bg-pattern.png') center $primary no-repeat;
    background-size: cover;

    &__inside {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top: calc(var(--header-height) + 3rem);
    }

    &__content {
        @extend %scrollbar-style;
        @include make-col;
        overflow-y: auto;

        a {
            @include link-variant($white, $secondary);
        }
    }

    &__main-menu {
        strong {
            font-weight: $font-weight-normal;
        }

        strong,
        a {
            display: inline-block;
            margin-bottom: calc(var(--font-size) * 2.2);

            &.active {
                color: $secondary;
            }
        }

        ul {
            @include list-unstyled;
            margin-bottom: 0;

            &.level_1 {
                margin-top: calc(var(--nav-margin) * -1);

                > li {
                    padding-top: var(--nav-margin);

                    > a,
                    > strong {
                        font-weight: $font-weight-extra-bold;
                        font-size: 2.5em;
                    }
                }
            }

            &.level_2 {
                margin-left: 2em;

                > li {
                    > a,
                    > strong {
                        font-size: 1.425em;
                    }

                    &:not(:last-child) {
                        ul.level_3 {
                            margin-bottom: 1em;
                        }
                    }
                }

                strong {
                    &::before {
                        position: relative;
                        top: -0.1em;
                        margin-right: rem(10px);
                        content: '—';
                    }
                }
            }

            &.level_3 {
                margin-left: 3.5em;
            }
        }
    }

    &__mobile-menu {
        margin-top: var(--nav-margin);
        font-size: 1.425em;

        ul {
            @extend %reset;
            margin-bottom: 0;

            > li:not(:last-child) {
                margin-bottom: calc(var(--font-size) * 2.2);
            }
        }
    }

    &__footer {
        margin-top: 3.2em;
        padding-top: 3.2em;
        padding-bottom: 3.2em;
        text-align: center;
        border-top: 1px solid $white;
    }

    @include media-breakpoint-down($header-breakpoint) {
        padding-top: calc(var(--header-height));

        &__inside {
            padding-top: 3rem;
            overflow-y: auto;
        }

        &__content {
            overflow: visible;
        }
    }

    @include media-breakpoint-up($header-breakpoint) {
        &__footer {
            margin-right: var(--column-one);
            margin-left: var(--column-one);
        }

        &__content {
            display: flex;
            flex-direction: column;
            padding-right: var(--column-one);
            padding-left: var(--column-one);
        }

        &__main-menu {
            max-width: 64em;
            margin: auto !important;

            ul.level_1 {
                > * {
                    display: inline-block;
                    float: left;
                    width: 33.333%;
                    padding-right: 1rem;

                    &.last {
                        float: right;
                    }
                }
            }

            ul.level_2 {
                margin-left: 0;
            }

            ul.level_3 {
                margin-top: 0.5rem;
            }
        }

        &__mobile-menu {
            display: none;
        }
    }

    @include media-breakpoint-up(xl) {
        &__content {
            &-menu {
                padding-left: var(--column-one);
            }
        }
    }
}

.header-contact {
    &__address {
        margin-bottom: 0.5em;
    }

    &__links {
        @include make-row-col(3em);
        justify-content: center;

        > * {
            position: relative;

            &::after {
                position: absolute;
                top: 0.1em;
                right: 0;
                bottom: 0.1em;
                width: 1px;
                background-color: $white;
                content: '';
            }

            &:last-child::after {
                content: none;
            }
        }

        a {
            @include link-variant(inherit, inherit);
        }

        &-phone {
            a {
                @include link-underline;
            }
        }

        &-email {
            a {
                @include link-underline-visible-hover;
            }
        }
    }
}

body.menu-active {
    --scrollbar-width: #{'0px'};
    overflow: hidden;

    .header-navigation {
        top: 0;
    }
}

/* Mobile menu trigger
HTML markup for menu trigger
<div class="mobile_menu_trigger__inside">
    <div class="mobile_menu_trigger__content">Mobile menu</div>
    <div class="mobile_menu_trigger__hamburger"><i></i></div>
</div>
*/
.menu-trigger {
    $this: &;
    z-index: 9;
    text-align: center;
    cursor: pointer;

    &__inside {
        @include transition(
            padding-top $transition-time,
            padding-bottom $transition-time,
            background-color $transition-time
        );
        @include padding-right(34px);
        @include padding-left(34px);
        @include padding-top(39px);
        @include padding-bottom(39px);
        display: flex;
        align-items: center;
        color: $white;
        background-color: $primary;
    }

    &__content {
        display: none;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    // Hamburger icon
    &__hamburger {
        --trigger-width: 28px;
        --trigger-height: 4px;
        --trigger-space: 6px;
        width: var(--trigger-width);
        padding: $mobile-menu-trigger-padding;
        vertical-align: middle;
        background: $mobile-menu-trigger-bg;

        &::after,
        &::before,
        i {
            display: block;
            height: var(--trigger-height);
            margin: var(--trigger-space) 0;
            background-color: $mobile-menu-trigger-color;
            border-radius: $mobile-menu-trigger-height;
            transition: $mobile-menu-trigger-transition;
            content: '';
        }

        &::before {
            margin-top: 0;
        }

        &::after {
            margin-bottom: 0;
        }

        &:not(:first-child) {
            margin-left: $mobile-menu-trigger-gap;
        }

        @include media-breakpoint-up($header-breakpoint) {
            --trigger-width: #{$mobile-menu-trigger-width};
            --trigger-height: #{$mobile-menu-trigger-height};
            --trigger-space: #{$mobile-menu-trigger-space};
        }
    }

    body.menu-active & {
        &__inside {
            background-color: transparent;
        }

        #{$this}__hamburger {
            &::before {
                transform: translateY(calc(var(--trigger-height) + var(--trigger-space))) rotate(135deg);
            }

            &::after {
                transform: translateY(calc((var(--trigger-height) + var(--trigger-space)) * -1)) rotate(-135deg);
            }

            i {
                transform: scale(0);
            }
        }
    }

    @include media-breakpoint-up($header-breakpoint) {
        &__inside {
            body:not(.menu-active) .sticky & {
                @include padding-top(25px);
                @include padding-bottom(25px);
            }
        }

        &__content {
            @include margin-left(16px);
            display: block;
        }
    }

    @if $mobile-menu-breakpoint {
        @include media-breakpoint-up($mobile-menu-breakpoint) {
            display: none;
        }
    }
}
