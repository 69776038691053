$call-to-action-breakpoint: md;

.call-to-action {
    --visual-offset: 0;
    --padding-y: #{rem(60px)};
    --padding-x: var(--column-one);

    $this: &;
    @include add-section-bg-100vw($body-bg);
    z-index: 1;

    &__wrapper {
        padding: var(--padding-y) var(--padding-x) 0 var(--padding-x);
        color: $white;
        background-color: $bg-dark;
        border-bottom: var(--visual-offset) solid $body-bg;
    }

    &__content {
        @extend %text-hyphens;
        margin-bottom: $vr;
    }

    &__headline {
        margin-bottom: 1em;
        font-size: 36px;
        line-height: 1;
        text-transform: uppercase;
    }

    &__text {
        position: relative;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        @include link-variant($white, $text-muted);
        margin-left: 0.5em;
        font-size: 0.75em;

        i {
            @include transition(transform $transition-time);
            display: inline-block;
        }

        &:hover {
            i {
                transform: translateX(0.25em);
            }
        }
    }

    &__visual {
        position: relative;
        z-index: 1;
        margin-bottom: calc(var(--visual-offset) * -1);
        margin-left: auto;

        &::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            z-index: -1;
            width: calc(100% + var(--padding-x) * 3);
            height: 35%;
            background: $body-bg;
            transform: translateX(-50%);
            content: '';
        }
    }

    &::after {
        top: 50%;
    }

    .section--bg-white & {
        margin-bottom: 0;
    }

    .section--bg-white &:last-child {
        margin-top: var(--article-margin);
        margin-bottom: calc(var(--article-padding-inside-y) * -1);
    }

    @include media-breakpoint-down($call-to-action-breakpoint) {
        &--bg-type-2 {
            max-width: 85%;
            margin-right: auto;
            margin-left: auto;
            padding-bottom: 10px;
        }
    }

    @include media-breakpoint-up($call-to-action-breakpoint) {
        &__wrapper {
            min-height: 296px;
            padding-bottom: var(--padding-y);

            &-row {
                position: relative;
                display: flex;
                align-items: center;
            }
        }

        &__content {
            position: relative;
            z-index: 2;
            flex: 0 0 var(--content-width);
            margin-bottom: 0;
        }

        &__headline {
            @include font-size(48px);
            margin-right: -2em;
            margin-bottom: 0.725em;
        }

        &__visual {
            @include rfs(-20px, top);
            @include rfs(-140px, bottom);
            position: absolute;
            right: 0;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: calc(100% - var(--content-width));
            margin-bottom: 0;
            padding-left: 0;

            &::after {
                display: none;
            }
        }

        &--bg-type-1 {
            --content-width: 62%;

            #{$this}__visual {
                img {
                    max-width: 140%;
                    transform: translate(3%, 13%);
                }
            }
        }

        &--bg-type-2 {
            --content-width: max(42%, 23em);

            #{$this}__visual {
                img {
                    max-width: 80%;
                }
            }
        }
    }
}
