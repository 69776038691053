.quote {
    @include font-size(48px);
    margin-top: var(--content-element-margin-lg);
    margin-bottom: var(--content-element-margin-lg);
    padding-right: var(--column-one);
    padding-left: var(--column-one);
    color: #d1d1d1;
    font-weight: $font-weight-semi-bold;
    font-style: italic;
    line-height: 1;
    letter-spacing: 0.02em;

    @include media-breakpoint-up(lg) {
        .section--bg-white & {
            margin-right: -1.5em;
            padding-right: 0;
        }
    }
}
